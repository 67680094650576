var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Drawer, IconButton, Tabs, Tab, List, ListItem, ListItemText, Typography, Button, } from '@mui/material';
import { Close, Mail, MailOutline, CheckCircle } from '@mui/icons-material';
import DraftsIcon from '@mui/icons-material/Drafts';
import { getNotificacoesUsuarioLogado, alterarNotificacao, alterarArrayNotificacoes, STATUS_API } from '../../api';
import BarraPesquisar from '../../components/grid_pesquisar';
import Grid, { PaginationModelDefault } from '../../components/grid';
import { FONT_SIZE } from '../../utils';
export var Notificacoes = function (_a) {
    var open = _a.open, handleClose = _a.handleClose;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState('naolidas'), StatusNotificacoes = _c[0], setStatusNotificacoes = _c[1];
    var _d = useState(''), TextoBusca = _d[0], setTextoBusca = _d[1];
    var _e = useState([]), NotificacoesArray = _e[0], setNotificacoesArray = _e[1];
    var _f = useState(0), TotalRegistros = _f[0], setTotalRegistros = _f[1];
    var _g = React.useState(PaginationModelDefault), PaginationModel = _g[0], setPaginationModel = _g[1];
    var handleChangeTab = function (_, newValue) {
        setStatusNotificacoes(newValue);
        filtrarGrid(TextoBusca, newValue);
    };
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, StatusParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (StatusParm === void 0) { StatusParm = StatusNotificacoes; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var Resposta, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //console.log('carregarDados : ' + JSON.stringify(PaginationModelParm));
                        setIsLoading(true);
                        return [4 /*yield*/, getNotificacoesUsuarioLogado(TextoBuscaParm, StatusParm, PaginationModelParm)];
                    case 1:
                        Resposta = _a.sent();
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setNotificacoesArray(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, StatusNotificacoes, PaginationModelParm);
    };
    var filtrarGrid = function (TextoParm, StatusParm, PaginationModelParm) {
        if (TextoParm === void 0) { TextoParm = TextoBusca; }
        if (StatusParm === void 0) { StatusParm = StatusNotificacoes; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        setTextoBusca(TextoParm);
        carregarDados(TextoParm, StatusParm, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var handleMarcarNotificacao = function (ID, Status) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setNotificacoesArray(function (prevArray) { return prevArray.map(function (notificacao) { return (notificacao.id === ID ? __assign(__assign({}, notificacao), { lida: Status }) : notificacao); }); });
                    setIsLoading(true);
                    return [4 /*yield*/, alterarNotificacao({ lida: Status }, ID)];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleMarcarTodos = function (Status) { return __awaiter(void 0, void 0, void 0, function () {
        var ArrayAux;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ArrayAux = NotificacoesArray.map(function (notificacao) { return (__assign(__assign({}, notificacao), { lida: Status })); });
                    setNotificacoesArray(ArrayAux);
                    setIsLoading(true);
                    return [4 /*yield*/, alterarArrayNotificacoes(ArrayAux)];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            field: 'notificacao',
            headerName: '',
            flex: 1,
            renderCell: function (params) {
                var notification = params.row;
                return (_jsxs(ListItem, __assign({ divider: true, sx: { width: '100%', marginTop: 0, paddingTop: 0, paddingLeft: 0 } }, { children: [_jsx(IconButton, __assign({ sx: { marginRight: 1 }, onClick: function () { return handleMarcarNotificacao(notification.id, !notification.lida); } }, { children: notification.lida ? _jsx(DraftsIcon, { style: { color: '#117845' } }) : _jsx(Mail, { style: { color: '#FA9C2E' } }) })), _jsx(ListItemText, { primary: _jsx(Typography, __assign({ sx: {
                                    fontWeight: notification.lida ? 'none' : 'bold',
                                    fontSize: FONT_SIZE.mediumLarge,
                                    wordBreak: 'break-word',
                                    whiteSpace: 'normal', // Permite quebra de texto
                                } }, { children: notification.titulo })), secondary: _jsx("span", { style: { fontSize: FONT_SIZE.medium }, dangerouslySetInnerHTML: { __html: notification.mensagem } }) })] }), notification.id));
            },
        },
    ];
    /*
    <IconButton edge='end' aria-label='delete' onClick={() => console.log('Excluir notificação', notification.id)}>
                <Delete />
              </IconButton>
    */
    return (_jsxs(Drawer, __assign({ anchor: 'right', open: open, onClose: handleClose, sx: { '& .MuiDrawer-paper': { width: 550, padding: 2 } } }, { children: [_jsxs(Box, __assign({ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5 }, { children: [_jsx(Typography, __assign({ variant: 'h6', sx: { fontWeight: 'bold' } }, { children: "Notifica\u00E7\u00F5es" })), _jsx(IconButton, __assign({ onClick: handleClose }, { children: _jsx(Close, {}) }))] })), _jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), _jsxs(Tabs, __assign({ value: StatusNotificacoes, onChange: handleChangeTab, indicatorColor: 'primary', textColor: 'primary', variant: 'fullWidth', sx: { marginTop: 2, marginBottom: 2 } }, { children: [_jsx(Tab, { label: 'N\u00E3o lidas', value: 'naolidas' }), _jsx(Tab, { label: 'Lidas', value: 'lidas' })] })), _jsxs(Box, __assign({ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }, { children: [_jsx(Box, __assign({ display: 'flex', alignItems: 'center', sx: {
                            cursor: 'pointer',
                            borderBottom: '2px solid #117845',
                            paddingBottom: '4px', // Ajusta o espaço entre o texto e o sublinhado
                        } }, { children: _jsxs(Button, __assign({ onClick: function () { return handleMarcarTodos(true); }, variant: 'text', sx: { margin: 0, padding: 0, textTransform: 'none' } }, { children: [_jsx(CheckCircle, { sx: { color: '#117845', marginRight: 1 }, fontSize: 'small' }), _jsx(Typography, __assign({ variant: 'caption' }, { children: "Marcar todos Lidos" }))] })) })), _jsx(Box, __assign({ display: 'flex', alignItems: 'center', sx: {
                            cursor: 'pointer',
                            borderBottom: '2px solid #FA9C2E',
                            paddingBottom: '4px',
                            marginLeft: 2,
                        } }, { children: _jsxs(Button, __assign({ onClick: function () { return handleMarcarTodos(false); }, sx: { margin: 0, padding: 0, textTransform: 'none' } }, { children: [_jsx(MailOutline, { sx: { color: '#FA9C2E', marginRight: 1 }, fontSize: 'small' }), _jsx(Typography, __assign({ variant: 'caption' }, { children: "Marcar todos N\u00E3o Lidos" }))] })) }))] })), _jsx(List, { children: _jsx(Grid, { rows: NotificacoesArray, columns: columns, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange, rowHeight: 90, sx: {
                        '.MuiDataGrid-columnHeaders': {
                            display: 'none',
                        },
                    } }) })] })));
};
export default Notificacoes;
