var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
var GridPesquisar = function (props) {
    //const { Link } = props;
    var filtrarGrid = props.filtrarGrid;
    var _a = React.useState(''), Texto = _a[0], setTexto = _a[1]; // Provide a default value of an empty string
    var handleKeyPress = function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            filtrarGrid(Texto);
        }
    };
    return (_jsxs(Paper, __assign({ component: 'form', sx: { p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' } }, { children: [_jsx(InputBase, { onChange: function (e) { return setTexto(e.target.value); }, onKeyDown: handleKeyPress, sx: { ml: 1, flex: 1 }, placeholder: 'Pesquisar', inputProps: { 'aria-label': 'search google maps' }, autoFocus: true }), _jsx(IconButton, __assign({ type: 'button', sx: { p: '10px' }, "aria-label": 'search', onClick: function () { return filtrarGrid(Texto); } }, { children: _jsx(SearchIcon, {}) }))] })));
};
export default GridPesquisar;
