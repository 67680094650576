var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { MenuItem, Grid, } from '@mui/material';
import { ExportarExcel, ExportarPDF } from '../../utils';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { ROUTES, PAGINAS } from '../../routes';
import { BotaoAdicionar } from '../../components/botoes';
import BarraPesquisar from '../../components/grid_pesquisar';
import GridComp from '../../components/grid';
import { PaginationModelDefault } from '../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../components/grid_box';
import { getAtendimentos, getCombosListar, gerarExcelAtendimentos, gerarPDFAtendimentos } from './encaminhamentos_controle_social.service';
import { STATUS_API } from '../../api';
import { useNavigate } from 'react-router-dom';
import InputDados from '../../components/input_dados';
export var AtendimentosControleSocialListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var navigate = useNavigate();
    var _b = useState([]), UsuariosDisponiveis = _b[0], setUsuariosDisponiveis = _b[1];
    var _c = useState(-1), BuscaAtendenteSelecionado = _c[0], setBuscaAtendenteSelecionado = _c[1];
    var _d = useState([]), SituacoesDisponiveis = _d[0], setSituacoesDisponiveis = _d[1];
    var _e = useState('-1'), BuscaSituacaoSelecionada = _e[0], setBuscaSituacaoSelecionada = _e[1];
    var _f = useState(''), BuscaDataInicio = _f[0], setBuscaDataInicio = _f[1];
    var _g = useState(''), BuscaDataFim = _g[0], setBuscaDataFim = _g[1];
    var _h = useState(''), TextoBusca = _h[0], setTextoBusca = _h[1];
    var _j = useState([]), OfertasVagaTrabalhos = _j[0], setOfertasVagaTrabalhos = _j[1];
    var _k = useState(0), TotalRegistros = _k[0], setTotalRegistros = _k[1];
    var _l = React.useState(PaginationModelDefault), PaginationModel = _l[0], setPaginationModel = _l[1];
    var _m = useState(false), AtendenteAdmin = _m[0], setAtendenteAdmin = _m[1];
    var _o = useContext(AuthContext) || { User: null, getAcoesFuncionalidade: function () { return []; } }, User = _o.User, getAcoesFuncionalidade = _o.getAcoesFuncionalidade;
    var _p = React.useState(getAcoesFuncionalidade(PAGINAS.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL)), AcoesFuncionalidade = _p[0], setAcoesFuncionalidade = _p[1];
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, BuscaSituacaoSelecionadaParm, BuscaAtendenteSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaSituacaoSelecionadaParm === void 0) { BuscaSituacaoSelecionadaParm = BuscaSituacaoSelecionada; }
        if (BuscaAtendenteSelecionadoParm === void 0) { BuscaAtendenteSelecionadoParm = BuscaAtendenteSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var AcoesFuncionalidadeAux, AtendenteAdminAux, CombosAtendimentos, usuarios, situacoes, Resposta, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        AcoesFuncionalidadeAux = getAcoesFuncionalidade(PAGINAS.ATENDIMENTOS_ADMIN);
                        AtendenteAdminAux = !!AcoesFuncionalidadeAux['VISUALIZAR'];
                        setAtendenteAdmin(AtendenteAdminAux);
                        if (!AtendenteAdminAux) {
                            setBuscaAtendenteSelecionado(User === null || User === void 0 ? void 0 : User.id);
                            BuscaAtendenteSelecionadoParm = User === null || User === void 0 ? void 0 : User.id;
                        }
                        if (!(SituacoesDisponiveis.length <= 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCombosListar()];
                    case 1:
                        CombosAtendimentos = _a.sent();
                        if (CombosAtendimentos.Status == STATUS_API.ERRO) {
                            alert(CombosAtendimentos.Mensagem);
                            setIsLoading(false);
                            return [2 /*return*/];
                        }
                        usuarios = CombosAtendimentos.usuarios, situacoes = CombosAtendimentos.situacoes;
                        setSituacoesDisponiveis(situacoes);
                        setUsuariosDisponiveis(usuarios);
                        _a.label = 2;
                    case 2: return [4 /*yield*/, getAtendimentos(TextoBuscaParm == '' ? null : TextoBuscaParm, BuscaSituacaoSelecionadaParm == '-1' ? null : BuscaSituacaoSelecionadaParm, BuscaAtendenteSelecionadoParm == -1 ? null : BuscaAtendenteSelecionadoParm === null || BuscaAtendenteSelecionadoParm === void 0 ? void 0 : BuscaAtendenteSelecionadoParm.toString(), BuscaDataInicioParm == '' ? null : BuscaDataInicioParm, BuscaDataFimParm == '' ? null : BuscaDataFimParm, PaginationModelParm)];
                    case 3:
                        Resposta = _a.sent();
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setOfertasVagaTrabalhos(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var filtrarGrid = function (TextoBuscaParm, BuscaSituacaoSelecionadaParm, BuscaAtendenteSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaSituacaoSelecionadaParm === void 0) { BuscaSituacaoSelecionadaParm = BuscaSituacaoSelecionada; }
        if (BuscaAtendenteSelecionadoParm === void 0) { BuscaAtendenteSelecionadoParm = BuscaAtendenteSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        setIsLoading(true);
        setTextoBusca(TextoBuscaParm);
        setBuscaSituacaoSelecionada(BuscaSituacaoSelecionadaParm);
        setBuscaAtendenteSelecionado(BuscaAtendenteSelecionadoParm);
        setBuscaDataInicio(BuscaDataInicioParm);
        setBuscaDataFim(BuscaDataFimParm);
        resetGrid(TextoBuscaParm, BuscaSituacaoSelecionadaParm, BuscaAtendenteSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModel);
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, BuscaSituacaoSelecionada, BuscaAtendenteSelecionado, BuscaDataInicio, BuscaDataFim, PaginationModelParm);
    };
    var resetGrid = function (TextoBuscaParm, BuscaSituacaoSelecionadaParm, BuscaAtendenteSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaSituacaoSelecionadaParm === void 0) { BuscaSituacaoSelecionadaParm = BuscaSituacaoSelecionada; }
        if (BuscaAtendenteSelecionadoParm === void 0) { BuscaAtendenteSelecionadoParm = BuscaAtendenteSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        carregarDados(TextoBuscaParm, BuscaSituacaoSelecionadaParm, BuscaAtendenteSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'id',
            headerName: 'Código',
            width: 100,
        },
        {
            field: 'reeducando',
            headerName: 'Egresso',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.reeducando) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'atendente',
            headerName: 'Atendente',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.atendente) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            width: 150,
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL,
        VISUALIZAR: AcoesFuncionalidade.VISUALIZAR,
        ALTERAR: AcoesFuncionalidade.ALTERAR,
        EXCLUIR: AcoesFuncionalidade.EXCLUIR,
    };
    var baixarPDF = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, gerarPDFAtendimentos(TextoBusca == '' ? null : TextoBusca, BuscaSituacaoSelecionada == '-1' ? null : BuscaSituacaoSelecionada, BuscaAtendenteSelecionado == -1 ? null : BuscaAtendenteSelecionado === null || BuscaAtendenteSelecionado === void 0 ? void 0 : BuscaAtendenteSelecionado.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "atendimentos.pdf";
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o pdf');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var baixarExcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, gerarExcelAtendimentos(TextoBusca == '' ? null : TextoBusca, BuscaSituacaoSelecionada == '-1' ? null : BuscaSituacaoSelecionada, BuscaAtendenteSelecionado == -1 ? null : BuscaAtendenteSelecionado === null || BuscaAtendenteSelecionado === void 0 ? void 0 : BuscaAtendenteSelecionado.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "atendimentos.xls";
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o excel');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), _jsx(BotaoAdicionar, { pagina: ROUTES.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL_REEDUCANDO, disabled: !AcoesFuncionalidade['CADASTRAR'] }), AcoesFuncionalidade['EXPORTAR PDF'] && _jsx(ExportarPDF, { onClick: baixarPDF }), AcoesFuncionalidade['EXPORTAR EXCEL'] && _jsx(ExportarExcel, { onClick: baixarExcel })] }), _jsxs(Grid, __assign({ container: true, spacing: 1, mt: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsx(InputDados, { id: 'data_inicio', label: 'Data In\u00EDcio', mask: '99/99/9999', value: BuscaDataInicio, onChange: function (event) {
                                    setBuscaDataInicio(event.target.value);
                                }, onBlur: function (event) {
                                    filtrarGrid(TextoBusca, BuscaSituacaoSelecionada, BuscaAtendenteSelecionado, event.target.value, BuscaDataFim);
                                }, size: 'small' }) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsx(InputDados, { id: 'data_fim', label: 'Data Fim', mask: '99/99/9999', value: BuscaDataFim, onChange: function (event) {
                                    setBuscaDataFim(event.target.value);
                                }, onBlur: function (event) {
                                    filtrarGrid(TextoBusca, BuscaSituacaoSelecionada, BuscaAtendenteSelecionado, BuscaDataInicio, event.target.value);
                                }, size: 'small' }) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'Atendente' // obrigatorio
                                , label: 'Atendente' // obrigatorio
                                , placeholder: 'Atendente', value: BuscaAtendenteSelecionado, onChange: function (event) {
                                    //const ValorAux = event.target.value;
                                    filtrarGrid(TextoBusca, BuscaSituacaoSelecionada, event.target.value, BuscaDataInicio, BuscaDataFim);
                                }, size: 'small', disabled: !AtendenteAdmin }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), UsuariosDisponiveis &&
                                        UsuariosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao' // obrigatorio
                                , label: 'Situa\u00E7\u00E3o' // obrigatorio
                                , placeholder: 'Situa\u00E7\u00E3o', value: BuscaSituacaoSelecionada, onChange: function (event) {
                                    filtrarGrid(TextoBusca, event.target.value, BuscaAtendenteSelecionado, BuscaDataInicio, BuscaDataFim);
                                }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), SituacoesDisponiveis &&
                                        SituacoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.nome }, { children: item.nome }), item.nome)); })] })) }))] })), _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_FUNCIONALIDADE, rows: OfertasVagaTrabalhos, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange, initialState: {
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc' }],
                            },
                        } }) })] }) })));
};
export default AtendimentosControleSocialListar;
