var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, MenuItem, Grid } from '@mui/material';
import { MODOS, MENSAGENS, validarCNPJ, getNomeFormatado } from '../../../../utils';
import { ROUTES, PAGINAS } from '../../../../routes';
import AuthContext from '../../../../contexts/auth';
import { BoxBotoes } from '../../../../components/form_box';
import Loading from '../../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { getInstituicaoDados, cadastrarInstituicao, alterarInstituicao, excluirInstituicao, consultarEnderecoCEP, getCombosInstituicoesDados, getCidadesAtivos, } from '../../../../api';
import { BotaoSalvar, BotaoExcluir, BotaoFechar, BotaoEditar } from '../../../../components/botoes';
import { InputDados } from '../../../../components/input_dados';
var InstituicoesTermosContratos = function (props) {
    var _a = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _a.DEV, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.CC_INSTITUICOES_DADOS)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState({}), InstituicaoDados = _f[0], setInstituicaoDados = _f[1];
    var _g = useState([]), CidadesDisponiveis = _g[0], setCidadesDisponiveis = _g[1];
    var _h = useState([]), EstadosDisponiveis = _h[0], setEstadosDisponiveis = _h[1];
    var _j = useState([]), TiposEmpresasDisponiveis = _j[0], setTiposEmpresasDisponiveis = _j[1];
    var _k = useState([]), TiposLogradourosDisponiveis = _k[0], setTiposLogradourosDisponiveis = _k[1];
    var _l = useState([]), OrgaosEmissoresRGDisponiveis = _l[0], setOrgaosEmissoresRGDisponiveis = _l[1];
    var _m = useState([]), BancosDisponiveis = _m[0], setBancosDisponiveis = _m[1];
    var _o = useState([]), TiposContasBancoDisponiveis = _o[0], setTiposContasBancoDisponiveis = _o[1];
    var _p = useState(), TipoEmpresaSelecionada = _p[0], setTipoEmpresaSelecionada = _p[1];
    var _q = useState(), AreaAtuacao = _q[0], setAreaAtuacao = _q[1];
    var _r = useState(''), CNPJ = _r[0], setCNPJ = _r[1];
    var _s = useState(), RazaoSocial = _s[0], setRazaoSocial = _s[1];
    var _t = useState(), NomeFantasia = _t[0], setNomeFantasia = _t[1];
    var _u = useState(), EnderecoCEP = _u[0], setEnderecoCEP = _u[1];
    var _v = useState(), EnderecoLogradouro = _v[0], setEnderecoLogradouro = _v[1];
    var _w = useState(), EnderecoNumero = _w[0], setEnderecoNumero = _w[1];
    var _x = useState(), EnderecoBairro = _x[0], setEnderecoBairro = _x[1];
    var _y = useState(), EnderecoComplemento = _y[0], setEnderecoComplemento = _y[1];
    var _z = useState(''), EnderecoCidade = _z[0], setEnderecoCidade = _z[1];
    var _0 = useState(''), EnderecoEstado = _0[0], setEnderecoEstado = _0[1];
    var _1 = useState(), EnderecoTipoLogradouroSelecionado = _1[0], setEnderecoTipoLogradouroSelecionado = _1[1];
    var _2 = useState(), Nome = _2[0], setNome = _2[1];
    var _3 = useState(), CPF = _3[0], setCPF = _3[1];
    var _4 = useState(), RGNumero = _4[0], setRGNumero = _4[1];
    var _5 = useState(), RGOrgaoEmissorSelecionado = _5[0], setRGOrgaoEmissorSelecionado = _5[1];
    var _6 = useState(), RGEstadoSelecionado = _6[0], setRGEstadoSelecionado = _6[1];
    var _7 = useState(), Email = _7[0], setEmail = _7[1];
    var _8 = useState(), Telefone = _8[0], setTelefone = _8[1];
    var _9 = useState(), Celular = _9[0], setCelular = _9[1];
    var _10 = useState(), ContaBancoSelecionado = _10[0], setContaBancoSelecionado = _10[1];
    var _11 = useState(), TipoContaSelecionada = _11[0], setTipoContaSelecionada = _11[1];
    var _12 = useState(), ContaAgencia = _12[0], setContaAgencia = _12[1];
    var _13 = useState(), ContaNumero = _13[0], setContaNumero = _13[1];
    var _14 = useState(), ContaDigito = _14[0], setContaDigito = _14[1];
    var _15 = useState('Ativo'), Situacao = _15[0], setSituacao = _15[1];
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR || !(AcoesFuncionalidade['ALTERAR'] || AcoesFuncionalidade['CADASTRAR']);
    var setAbaAtiva = props.setAbaAtiva;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var DadosCombos, InstituicaoDadosAux, tipo_empresa_id, area_atuacao, cnpj, razao_social, nome_fantasia, endereco_cep, endereco_tipo_logradouro_id, endereco_logradouro, endereco_numero, endereco_bairro, endereco_complemento, endereco_estado_id, endereco_cidade_id, responsavel_nome, responsavel_cpf, responsavel_rg, responsavel_orgao_emissor_rg_id, responsavel_org_emi_rg_estado_id, responsavel_email, responsavel_telefone, responsavel_celular, conta_banco_id, conta_tipo_id, conta_agencia, conta_numero, conta_digito, situacao, CidadeID_1, EstadoID_1, OrgaoEmissorRGID_1, OrgaoEmissorRGEstadoID_1, EstadoAux, CidadesAux, CidadeAux, OrgaoEmissorAux, EstadoAux;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, getCombosInstituicoesDados()];
                case 1:
                    DadosCombos = _d.sent();
                    if (DadosCombos) {
                        setTiposEmpresasDisponiveis(DadosCombos.tipos_empresas);
                        setEstadosDisponiveis(DadosCombos.estados);
                        setTiposLogradourosDisponiveis(DadosCombos.tipos_logradouros);
                        setOrgaosEmissoresRGDisponiveis(DadosCombos.orgaos_emissores_rg);
                        setBancosDisponiveis(DadosCombos.bancos);
                        setTiposContasBancoDisponiveis(DadosCombos.tipos_contas_bancarias);
                    }
                    if (!id) return [3 /*break*/, 5];
                    return [4 /*yield*/, getInstituicaoDados(id)];
                case 2:
                    InstituicaoDadosAux = _d.sent();
                    setInstituicaoDados(InstituicaoDadosAux);
                    if (!InstituicaoDadosAux) return [3 /*break*/, 5];
                    tipo_empresa_id = InstituicaoDadosAux.tipo_empresa_id, area_atuacao = InstituicaoDadosAux.area_atuacao, cnpj = InstituicaoDadosAux.cnpj, razao_social = InstituicaoDadosAux.razao_social, nome_fantasia = InstituicaoDadosAux.nome_fantasia, endereco_cep = InstituicaoDadosAux.endereco_cep, endereco_tipo_logradouro_id = InstituicaoDadosAux.endereco_tipo_logradouro_id, endereco_logradouro = InstituicaoDadosAux.endereco_logradouro, endereco_numero = InstituicaoDadosAux.endereco_numero, endereco_bairro = InstituicaoDadosAux.endereco_bairro, endereco_complemento = InstituicaoDadosAux.endereco_complemento, endereco_estado_id = InstituicaoDadosAux.endereco_estado_id, endereco_cidade_id = InstituicaoDadosAux.endereco_cidade_id, responsavel_nome = InstituicaoDadosAux.responsavel_nome, responsavel_cpf = InstituicaoDadosAux.responsavel_cpf, responsavel_rg = InstituicaoDadosAux.responsavel_rg, responsavel_orgao_emissor_rg_id = InstituicaoDadosAux.responsavel_orgao_emissor_rg_id, responsavel_org_emi_rg_estado_id = InstituicaoDadosAux.responsavel_org_emi_rg_estado_id, responsavel_email = InstituicaoDadosAux.responsavel_email, responsavel_telefone = InstituicaoDadosAux.responsavel_telefone, responsavel_celular = InstituicaoDadosAux.responsavel_celular, conta_banco_id = InstituicaoDadosAux.conta_banco_id, conta_tipo_id = InstituicaoDadosAux.conta_tipo_id, conta_agencia = InstituicaoDadosAux.conta_agencia, conta_numero = InstituicaoDadosAux.conta_numero, conta_digito = InstituicaoDadosAux.conta_digito, situacao = InstituicaoDadosAux.situacao;
                    setTipoEmpresaSelecionada(tipo_empresa_id);
                    setAreaAtuacao(area_atuacao);
                    setCNPJ(cnpj);
                    setRazaoSocial(razao_social);
                    setNomeFantasia(nome_fantasia);
                    setEnderecoCEP(endereco_cep);
                    setEnderecoTipoLogradouroSelecionado(endereco_tipo_logradouro_id);
                    setEnderecoLogradouro(endereco_logradouro);
                    setEnderecoNumero(endereco_numero);
                    setEnderecoBairro(endereco_bairro);
                    setEnderecoComplemento(endereco_complemento);
                    setNome(responsavel_nome);
                    setCPF(responsavel_cpf);
                    setRGNumero(responsavel_rg);
                    setEmail(responsavel_email);
                    setTelefone(responsavel_telefone);
                    setCelular(responsavel_celular);
                    setContaBancoSelecionado(conta_banco_id);
                    setTipoContaSelecionada(conta_tipo_id);
                    setContaAgencia(conta_agencia);
                    setContaNumero(conta_numero);
                    setContaDigito(conta_digito);
                    setSituacao(situacao);
                    CidadeID_1 = endereco_cidade_id;
                    EstadoID_1 = endereco_estado_id;
                    OrgaoEmissorRGID_1 = responsavel_orgao_emissor_rg_id;
                    OrgaoEmissorRGEstadoID_1 = responsavel_org_emi_rg_estado_id;
                    if (!EstadoID_1) return [3 /*break*/, 4];
                    EstadoAux = (_a = DadosCombos.estados) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id == EstadoID_1; });
                    setEnderecoEstado(EstadoAux === null || EstadoAux === void 0 ? void 0 : EstadoAux.sigla);
                    return [4 /*yield*/, getCidadesAtivos(EstadoAux === null || EstadoAux === void 0 ? void 0 : EstadoAux.sigla)];
                case 3:
                    CidadesAux = _d.sent();
                    setCidadesDisponiveis(CidadesAux);
                    if (CidadeID_1) {
                        CidadeAux = CidadesAux === null || CidadesAux === void 0 ? void 0 : CidadesAux.find(function (item) { return item.id == CidadeID_1; });
                        setEnderecoCidade(CidadeAux === null || CidadeAux === void 0 ? void 0 : CidadeAux.codigo_ibge);
                    }
                    _d.label = 4;
                case 4:
                    if (OrgaoEmissorRGID_1) {
                        OrgaoEmissorAux = (_b = DadosCombos.orgaos_emissores_rg) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.id == OrgaoEmissorRGID_1; });
                        setRGOrgaoEmissorSelecionado(OrgaoEmissorAux === null || OrgaoEmissorAux === void 0 ? void 0 : OrgaoEmissorAux.sigla);
                    }
                    if (OrgaoEmissorRGEstadoID_1) {
                        EstadoAux = (_c = DadosCombos.estados) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.id == OrgaoEmissorRGEstadoID_1; });
                        setRGEstadoSelecionado(EstadoAux === null || EstadoAux === void 0 ? void 0 : EstadoAux.sigla);
                    }
                    _d.label = 5;
                case 5:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!TipoEmpresaSelecionada || TipoEmpresaSelecionada == -1) {
            alert("Tipo de empresa ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!CNPJ || CNPJ.trim() == '') {
            alert("CNPJ ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        var RegexCNPJ = new RegExp(/^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}\-?\d{2}$/);
        if (!RegexCNPJ.test(CNPJ) || !validarCNPJ(CNPJ)) {
            alert('O CNPJ informado é inválido');
            return false;
        }
        if (!RazaoSocial || RazaoSocial.trim() == '') {
            alert("Raz\u00E3o Social ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!NomeFantasia || NomeFantasia.trim() == '') {
            alert("Nome fantasia ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!Nome || Nome.trim() == '') {
            alert("Nome do respons\u00E1vel ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        var RegexEmail = new RegExp(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/);
        if (Email && !RegexEmail.test(Email)) {
            alert('O Email informado é inválido');
            return false;
        }
        if (Telefone && Telefone.trim().length < 10) {
            alert('Telefone inválido');
            return false;
        }
        if (EnderecoCEP && EnderecoCEP.trim().length < 8) {
            alert('CEP inválido');
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var EstadoID, RGEstadoID, OrgaoEmissorRGID, CidadeID, Dados, Resp, _a;
        var _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    EstadoID = (_b = EstadosDisponiveis === null || EstadosDisponiveis === void 0 ? void 0 : EstadosDisponiveis.find(function (item) { return item.sigla == EnderecoEstado; })) === null || _b === void 0 ? void 0 : _b.id;
                    RGEstadoID = (_c = EstadosDisponiveis === null || EstadosDisponiveis === void 0 ? void 0 : EstadosDisponiveis.find(function (item) { return item.sigla == RGEstadoSelecionado; })) === null || _c === void 0 ? void 0 : _c.id;
                    OrgaoEmissorRGID = (_d = OrgaosEmissoresRGDisponiveis === null || OrgaosEmissoresRGDisponiveis === void 0 ? void 0 : OrgaosEmissoresRGDisponiveis.find(function (item) { return item.sigla == RGOrgaoEmissorSelecionado; })) === null || _d === void 0 ? void 0 : _d.id;
                    CidadeID = (_e = CidadesDisponiveis === null || CidadesDisponiveis === void 0 ? void 0 : CidadesDisponiveis.find(function (item) { return item.codigo_ibge == EnderecoCidade; })) === null || _e === void 0 ? void 0 : _e.id;
                    Dados = {
                        cnpj: CNPJ.replace(/[^0-9]/g, ''),
                        tipo_empresa_id: TipoEmpresaSelecionada,
                        area_atuacao: AreaAtuacao,
                        razao_social: RazaoSocial,
                        nome_fantasia: NomeFantasia,
                        endereco_cep: EnderecoCEP === null || EnderecoCEP === void 0 ? void 0 : EnderecoCEP.replace(/[^0-9]/g, ''),
                        endereco_tipo_logradouro_id: EnderecoTipoLogradouroSelecionado,
                        endereco_logradouro: EnderecoLogradouro,
                        endereco_numero: EnderecoNumero,
                        endereco_bairro: EnderecoBairro,
                        endereco_complemento: EnderecoComplemento,
                        endereco_cidade_id: CidadeID,
                        endereco_estado_id: EstadoID,
                        responsavel_nome: Nome ? getNomeFormatado(Nome) : null,
                        responsavel_cpf: CPF === null || CPF === void 0 ? void 0 : CPF.replace(/[^0-9]/g, ''),
                        responsavel_rg: RGNumero,
                        responsavel_orgao_emissor_rg_id: OrgaoEmissorRGID,
                        responsavel_org_emi_rg_estado_id: RGEstadoID,
                        responsavel_email: Email,
                        responsavel_telefone: Telefone === null || Telefone === void 0 ? void 0 : Telefone.replace(/[^0-9]/g, ''),
                        responsavel_celular: Celular === null || Celular === void 0 ? void 0 : Celular.replace(/[^0-9]/g, ''),
                        conta_banco_id: ContaBancoSelecionado,
                        conta_tipo_id: TipoContaSelecionada,
                        conta_agencia: ContaAgencia,
                        conta_numero: ContaNumero,
                        conta_digito: ContaDigito,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, cadastrarInstituicao(Dados)];
                case 2:
                    Resp = _g.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, alterarInstituicao(Dados, id !== null && id !== void 0 ? id : '')];
                case 4:
                    Resp = _g.sent();
                    return [3 /*break*/, 5];
                case 5:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        alert(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        modo == MODOS.CADASTRAR && editarRegistro((_f = Resp === null || Resp === void 0 ? void 0 : Resp.dados.id) === null || _f === void 0 ? void 0 : _f.toString());
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var excluirRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, excluirInstituicao(id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MENSAGENS.REGISTRO_EXCLUIDO_SUCESSO);
                        navigate(ROUTES.CC_INSTITUICOES);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function (IDParm) {
        if (IDParm === void 0) { IDParm = id; }
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                navigate("".concat(ROUTES.CC_INSTITUICOES, "/").concat(IDParm, "/").concat(MODOS.ALTERAR));
                return [2 /*return*/];
            });
        });
    };
    var buscarCEP = function (CEPParm) { return __awaiter(void 0, void 0, void 0, function () {
        var CEP, Endereco;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    CEP = CEPParm.replace(/[^0-9]/g, '');
                    if (CEP.length < 8)
                        return [2 /*return*/];
                    setLoading(true);
                    DEV && console.log('CEP', CEP);
                    return [4 /*yield*/, consultarEnderecoCEP(CEP)];
                case 1:
                    Endereco = _a.sent();
                    DEV && console.log('Endereco', JSON.stringify(Endereco));
                    if (!(Endereco && Endereco.cep)) return [3 /*break*/, 3];
                    setEnderecoLogradouro(Endereco.logradouro);
                    setEnderecoBairro(Endereco.bairro);
                    setEnderecoComplemento(Endereco.complemento);
                    return [4 /*yield*/, onChangeEstado(Endereco.uf)];
                case 2:
                    _a.sent();
                    setEnderecoCidade(Endereco.ibge);
                    return [3 /*break*/, 4];
                case 3:
                    alert('CEP não encontrado');
                    _a.label = 4;
                case 4:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeEstado = function (Estado) { return __awaiter(void 0, void 0, void 0, function () {
        var CidadesAux;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setEnderecoEstado(Estado);
                    return [4 /*yield*/, getCidadesAtivos(Estado)];
                case 1:
                    CidadesAux = _a.sent();
                    setCidadesDisponiveis(CidadesAux);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [!LoadingInicio && (_jsxs(Box, __assign({ sx: { mt: 2 } }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Dados B\u00E1sicos" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 5 }, { children: _jsx(InputDados, { id: 'razao-social', label: 'Raz\u00E3o Social', value: RazaoSocial, onChange: function (event) { return setRazaoSocial(event.target.value); }, required: true, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 5 }, { children: _jsx(InputDados, { id: 'nome-fantasia', label: 'Nome Fantasia', value: NomeFantasia, onChange: function (event) { return setNomeFantasia(event.target.value); }, required: true, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'cnpj', label: 'CNPJ', value: CNPJ, onChange: function (event) { return setCNPJ(event.target.value); }, mask: '99.999.999/9999-99', required: true, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-empresa', label: 'Tipo de Empresa', placeholder: 'Tipo de Empresa', value: TipoEmpresaSelecionada, onChange: function (event) {
                                        setTipoEmpresaSelecionada(event.target.value);
                                    }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), TiposEmpresasDisponiveis &&
                                            TiposEmpresasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'area-atuacao', label: '\u00C1rea de Atua\u00E7\u00E3o', value: AreaAtuacao, onChange: function (event) { return setAreaAtuacao(event.target.value); }, disabled: DESABILITAR_CAMPOS }) }))] })), _jsxs(Box, __assign({ sx: { mt: 4 } }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Endere\u00E7o" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'cep', label: 'CEP', value: EnderecoCEP, onChange: function (event) { return setEnderecoCEP(event.target.value); }, onBlur: function (event) { return buscarCEP(event.target.value); }, mask: '99.999-999', disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, { item: true, xs: 12, sm: 10 }), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-logradouro', label: 'Tipo de Logradouro', placeholder: 'Tipo de Logradouro', value: EnderecoTipoLogradouroSelecionado, onChange: function (event) {
                                                setEnderecoTipoLogradouroSelecionado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), TiposLogradourosDisponiveis &&
                                                    TiposLogradourosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'logradouro', label: 'Logradouro', value: EnderecoLogradouro, onChange: function (event) { return setEnderecoLogradouro(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'numero', label: 'N\u00FAmero', value: EnderecoNumero, onChange: function (event) { return setEnderecoNumero(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'bairro', label: 'Bairro', value: EnderecoBairro, onChange: function (event) { return setEnderecoBairro(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'complemento', label: 'Complemento', value: EnderecoComplemento, onChange: function (event) { return setEnderecoComplemento(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: EnderecoEstado && (_jsxs(InputDados, __assign({ select: true, id: 'estado', label: 'Estado', s: true, placeholder: 'Estado', value: EnderecoEstado, onChange: function (event) {
                                                onChangeEstado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), EstadosDisponiveis &&
                                                    EstadosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.sigla }, { children: item.nome }), item.sigla)); })] }))) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: EnderecoCidade && (_jsxs(InputDados, __assign({ select: true, id: 'cidade', label: 'Cidade', placeholder: 'Cidade', value: EnderecoCidade, onChange: function (event) {
                                                setEnderecoCidade(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), CidadesDisponiveis &&
                                                    CidadesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.codigo_ibge }, { children: item.nome }), item.codigo_ibge)); })] }))) }))] }))] })), _jsxs(Box, __assign({ sx: { mt: 4 } }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Dados do Respons\u00E1vel" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'nome', label: 'Nome', value: Nome, onChange: function (event) { return setNome(event.target.value); }, required: true, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'cpf', label: 'CPF', value: CPF, mask: '999.999.999-99', onChange: function (event) { return setCPF(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'rg', label: 'RG', value: RGNumero, onChange: function (event) { return setRGNumero(event.target.value); }, mask: '99999999999', disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: OrgaosEmissoresRGDisponiveis && (_jsxs(InputDados, __assign({ select: true, id: 'orgao-emissor-rg', label: '\u00D3rg\u00E3o Emissor do RG', placeholder: '\u00D3rg\u00E3o Emissor do RG', value: RGOrgaoEmissorSelecionado, onChange: function (event) {
                                                setRGOrgaoEmissorSelecionado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), OrgaosEmissoresRGDisponiveis &&
                                                    OrgaosEmissoresRGDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.sigla }, { children: item.sigla }), item.id)); })] }))) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: EstadosDisponiveis && (_jsxs(InputDados, __assign({ select: true, id: 'estado', label: 'Estado', placeholder: 'Estado', value: RGEstadoSelecionado, onChange: function (event) {
                                                setRGEstadoSelecionado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), EstadosDisponiveis &&
                                                    EstadosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.sigla }, { children: item.nome }), item.sigla)); })] }))) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'email', label: 'Email', value: Email, onChange: function (event) { return setEmail(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'telefone', label: 'Telefone', value: Telefone, onChange: function (event) { return setTelefone(event.target.value); }, mask: '(99) 9999-9999', disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'celular', label: 'Celular', value: Celular, onChange: function (event) { return setCelular(event.target.value); }, mask: '(99) 9999-9999', disabled: DESABILITAR_CAMPOS }) }))] }))] })), _jsxs(Box, __assign({ sx: { mt: 4 } }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Dados Banc\u00E1rios" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 5 }, { children: BancosDisponiveis && (_jsxs(InputDados, __assign({ select: true, id: 'Banco', label: 'Banco', placeholder: 'Banco', value: ContaBancoSelecionado, onChange: function (event) {
                                                setContaBancoSelecionado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), BancosDisponiveis &&
                                                    BancosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: "".concat(item.codigo, " - ").concat(item.nome) }), item.id)); })] }))) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2.5 }, { children: TiposContasBancoDisponiveis && (_jsxs(InputDados, __assign({ select: true, id: 'tipo-de-conta', label: 'Tipo de Conta', placeholder: 'Tipo de Conta', value: TipoContaSelecionada, onChange: function (event) {
                                                setTipoContaSelecionada(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), TiposContasBancoDisponiveis &&
                                                    TiposContasBancoDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] }))) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 1.5 }, { children: _jsx(InputDados, { id: 'conta-agencia', label: 'N\u00FAmero da Ag\u00EAncia', value: ContaAgencia, onChange: function (event) { return setContaAgencia(event.target.value); }, mask: '99999', disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 1.5 }, { children: _jsx(InputDados, { id: 'conta-numero', label: 'N\u00FAmero da Conta', value: ContaNumero, onChange: function (event) { return setContaNumero(event.target.value); }, mask: '999999999999', disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 1.5 }, { children: _jsx(InputDados, { id: 'digito-conta', label: 'D\u00EDgito da Conta', value: ContaDigito, onChange: function (event) { return setContaDigito(event.target.value); }, mask: '9', disabled: DESABILITAR_CAMPOS }) }))] }))] })), _jsxs(BoxBotoes, __assign({ sx: { marginTop: 10 } }, { children: [(MODOS.ALTERAR == modo || MODOS.CADASTRAR == modo) && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.EXCLUIR == modo && _jsx(BotaoExcluir, { onClick: excluirRegistro }), MODOS.VISUALIZAR == modo && _jsx(BotaoEditar, { onClick: function () { return editarRegistro(id); }, disabled: !AcoesFuncionalidade['ALTERAR'] }), _jsx(BotaoFechar, { modo: modo, pagina: ROUTES.CC_INSTITUICOES })] }))] }))), loading && _jsx(Loading, {})] }));
};
export default InstituicoesTermosContratos;
