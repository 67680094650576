var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Button, IconButton, MenuItem, Paper, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme, InputBase, } from '@mui/material';
import { MODOS, MENSAGENS, getCPFFormatado, } from '../../utils';
import { ROUTES, PAGINAS } from '../../routes';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../components/form_box';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoSalvar, BotaoFechar } from '../../components/botoes';
import { InputDados } from '../../components/input_dados';
import { getCombosCadastro, STATUS_API, cadastrarAtendimento } from './encaminhamentos_controle_social.service';
import { ReeducandosBusca } from '../reeducandos';
import { Search } from '@mui/icons-material';
export var AtendimentosControleSocialReeducando = function (props) {
    var _a = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _a.DEV, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL_REEDUCANDO)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState([]), SituacoesDisponiveis = _f[0], setSituacoesDisponiveis = _f[1];
    var _g = useState(), Reeducando = _g[0], setReeducando = _g[1];
    var _h = useState(-1), ReeducandoSelecionado = _h[0], setReeducandoSelecionado = _h[1];
    var _j = useState('EM ATENDIMENTO'), SituacaoSelecionada = _j[0], setSituacaoSelecionada = _j[1];
    var _k = useState(false), PopupAbertoReeducando = _k[0], setPopupAbertoReeducando = _k[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var CombosEditar, situacoes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCombosCadastro()];
                case 1:
                    CombosEditar = _a.sent();
                    if (CombosEditar.Status == STATUS_API.ERRO) {
                        alert(CombosEditar.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        situacoes = CombosEditar.situacoes;
                        setSituacoesDisponiveis(situacoes);
                    }
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (ReeducandoSelecionado == -1) {
            alert("Reeducando ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        reeducando_id: ReeducandoSelecionado,
                        situacao: SituacaoSelecionada,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, cadastrarAtendimento(Dados)];
                case 2:
                    Resp = _c.sent();
                    return [3 /*break*/, 3];
                case 3:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        navigate("".concat(ROUTES.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL, "/").concat((_b = Resp === null || Resp === void 0 ? void 0 : Resp.dados) === null || _b === void 0 ? void 0 : _b.id, "/").concat(MODOS.ALTERAR));
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        setPopupAbertoReeducando(false);
        carregarDados();
    };
    var selecionarReeducando = function (reeducando) {
        setReeducando(reeducando);
        setReeducandoSelecionado(reeducando.id);
        setPopupAbertoReeducando(false);
    };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL, link_pagina_acima: ROUTES.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL }, { children: _jsxs(BoxPrincipal, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Paper, __assign({ component: 'form', sx: { p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' } }, { children: [_jsx(InputBase, { sx: { ml: 1, flex: 1 }, placeholder: 'Reeducando', inputProps: { 'aria-label': 'search google maps' }, autoFocus: true, value: Reeducando ? "".concat(Reeducando === null || Reeducando === void 0 ? void 0 : Reeducando.nome, " - CPF: ").concat(getCPFFormatado(Reeducando === null || Reeducando === void 0 ? void 0 : Reeducando.cpf)) : '', disabled: true }), _jsx(IconButton, __assign({ type: 'button', sx: { p: '10px' }, "aria-label": 'search', onClick: function () {
                                        setPopupAbertoReeducando(true);
                                    } }, { children: _jsx(Search, {}) }))] })), _jsx(InputDados, __assign({ select: true, label: 'Situa\u00E7\u00E3o', value: SituacaoSelecionada, onChange: function (event) { return setSituacaoSelecionada(event.target.value); }, required: true, disabled: DESABILITAR_CAMPOS }, { children: SituacoesDisponiveis &&
                                SituacoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); }) })), _jsxs(BoxBotoes, { children: [(MODOS.ALTERAR == modo || MODOS.CADASTRAR == modo) && _jsx(BotaoSalvar, { onClick: salvarDados }), _jsx(BotaoFechar, { modo: modo })] }), _jsxs(Dialog, __assign({ open: PopupAbertoReeducando, onClose: handleClose, maxWidth: 'xl' }, { children: [_jsx(DialogContent, __assign({ style: { margin: 0 } }, { children: _jsx(ReeducandosBusca, { selecionarReeducando: selecionarReeducando }) })), _jsx(DialogActions, __assign({ style: { padding: 20 } }, { children: _jsx(Button, __assign({ onClick: handleClose }, { children: "Voltar" })) }))] }))] })), loading && _jsx(Loading, {})] }) })));
};
export default AtendimentosControleSocialReeducando;
