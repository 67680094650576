var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Tab, Tabs, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { MODOS } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal } from '../../../components/form_box';
import { useNavigate, useParams } from 'react-router-dom';
import InstituicoesDados from './instituicoes_dados';
import InstituicoesDocumentos from './instituicoes_documentos';
import InstituicoesTermosContratos from './instituicoes_termos_contratos';
var InstituicoesAlterar = function (props) {
    var _a = useContext(AuthContext) || { addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.CC_INSTITUICOES)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useState(true), visibilidadeAbaDados = _e[0], setvisibilidadeAbaDados = _e[1];
    var _f = useState(true), visibilidadeAbaDocumentos = _f[0], setvisibilidadeAbaDocumentos = _f[1];
    var _g = useState(true), visibilidadeAbaTermosContratos = _g[0], setvisibilidadeAbaTermosContratos = _g[1];
    var _h = useParams(), id = _h.id, modo = _h.modo;
    var _j = React.useState(0), value = _j[0], setValue = _j[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, [modo]);
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var visibilidadeAbaDadosAux, visibilidadeAbaDocumentosAux, visibilidadeAbaTermosContratosAux;
        return __generator(this, function (_a) {
            setLoadingInicio(false);
            visibilidadeAbaDadosAux = Object.keys(getAcoesFuncionalidade(PAGINAS.CC_INSTITUICOES_DADOS)).length > 0;
            setvisibilidadeAbaDados(visibilidadeAbaDadosAux);
            visibilidadeAbaDocumentosAux = Object.keys(getAcoesFuncionalidade(PAGINAS.CC_INSTITUICOES_DOCUMENTOS)).length > 0;
            setvisibilidadeAbaDocumentos(visibilidadeAbaDocumentosAux && modo != MODOS.CADASTRAR);
            visibilidadeAbaTermosContratosAux = Object.keys(getAcoesFuncionalidade(PAGINAS.CC_INSTITUICOES_TERMOS)).length > 0;
            setvisibilidadeAbaTermosContratos(visibilidadeAbaTermosContratosAux && modo != MODOS.CADASTRAR);
            setLoading(false);
            return [2 /*return*/];
        });
    }); };
    function a11yProps(index) {
        return {
            id: "simple-tab-".concat(index),
            'aria-controls': "simple-tabpanel-".concat(index),
        };
    }
    var TabPanel = function (props) {
        var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
        return (_jsx("div", __assign({ role: 'tabpanel', hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { style: { width: '100%' } }, { children: value === index && (_jsx(Box, __assign({ sx: { p: 3, width: '100%' } }, { children: _jsx(Typography, { children: children }) }))) })));
    };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.CC_INSTITUICOES, link_pagina_acima: ROUTES.CC_INSTITUICOES }, { children: _jsx(BoxPrincipal, __assign({ sx: { width: isMobile ? '100%' : '100%', mt: -3 } }, { children: _jsxs(Box, { children: [_jsx(Grid, __assign({ container: true, justifyContent: 'center' }, { children: _jsxs(Tabs, __assign({ value: value, onChange: handleChange, "aria-label": 'tabs', variant: isMobile ? 'fullWidth' : 'standard', scrollButtons: 'auto', centered: !isMobile }, { children: [_jsx(Tab, __assign({ disabled: !visibilidadeAbaDados, label: 'Dados da Institui\u00E7\u00E3o' }, a11yProps(0))), _jsx(Tab, __assign({ disabled: !visibilidadeAbaDocumentos, label: 'Documentos' }, a11yProps(1))), _jsx(Tab, __assign({ disabled: !visibilidadeAbaTermosContratos, label: 'Termos / Contratos' }, a11yProps(2)))] })) })), _jsx(TabPanel, __assign({ value: value, index: 0 }, { children: _jsx(InstituicoesDados, __assign({}, props, { setAbaAtiva: setValue })) })), _jsx(TabPanel, __assign({ value: value, index: 1 }, { children: _jsx(InstituicoesDocumentos, __assign({}, props, { setAbaAtiva: setValue })) })), _jsx(TabPanel, __assign({ value: value, index: 2 }, { children: _jsx(InstituicoesTermosContratos, __assign({}, props, { setAbaAtiva: setValue })) }))] }) })) })));
};
export default InstituicoesAlterar;
