var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { GlobalStyles, Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import AuthContext from '../../contexts/auth';
var Header = function (props) {
    var pagina = props.pagina, modo = props.modo;
    var _a = useContext(AuthContext) || {
        auth: function () { },
        User: null,
    }, auth = _a.auth, User = _a.User;
    useEffect(function () {
        verificarAuth();
        return function () { };
    }, []);
    var verificarAuth = function () {
        if (pagina && modo) {
            if (User) {
                auth(null, pagina, modo);
            }
            else {
                var params = new Proxy(new URLSearchParams(window.location.search), {
                    get: function (searchParams, prop) { return searchParams.get(String(prop)); },
                });
                var code = params.code;
                auth(code, pagina, modo); //Caso tenha acabado de logar e esta na Home do Painel
            }
        }
    };
    return (_jsx(Box, __assign({ sx: {
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            width: '100vw',
            height: 'var(--Header-height)',
            zIndex: 1000,
            borderBottom: '1px solid',
            borderColor: 'background.level1',
            boxShadow: 'sm',
        } }, { children: _jsx(GlobalStyles, { styles: function (theme) {
                var _a;
                return ({
                    ':root': (_a = {
                            '--Header-height': '52px'
                        },
                        _a[theme.breakpoints.up('md')] = {
                            '--Header-height': '0px',
                        },
                        _a),
                });
            } }) })));
};
export default Header;
