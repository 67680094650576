var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { MODOS, MENSAGENS, getNomeFormatado } from '../../utils';
import { ROUTES, PAGINAS } from '../../routes';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../components/form_box';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { getIdentidadeGenero, cadastrarIdentidadeGenero, alterarIdentidadeGenero, excluirIdentidadeGenero } from '../../api';
import { BotaoSalvar, BotaoExcluir, BotaoFechar, BotaoEditar } from '../../components/botoes';
var IdentidadesGeneroEditar = function (props) {
    var _a = useContext(AuthContext) || { addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.IDENTIDADES_GENERO)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState({}), IdentidadeGenero = _f[0], setIdentidadeGenero = _f[1];
    var _g = useState(''), Nome = _g[0], setNome = _g[1];
    var _h = useState('Ativo'), Situacao = _h[0], setSituacao = _h[1];
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var IdentidadeGeneroAux, nome, situacao;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, getIdentidadeGenero(id)];
                case 1:
                    IdentidadeGeneroAux = _a.sent();
                    setIdentidadeGenero(IdentidadeGeneroAux);
                    //console.log('IdentidadeGenero', JSON.stringify(IdentidadeGeneroAux));
                    if (IdentidadeGeneroAux) {
                        nome = IdentidadeGeneroAux.nome, situacao = IdentidadeGeneroAux.situacao;
                        setNome(nome);
                        setSituacao(situacao);
                    }
                    _a.label = 2;
                case 2:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        var ValidouDados = true;
        if (!Nome || Nome.trim() == '')
            ValidouDados = false;
        return ValidouDados;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        nome: getNomeFormatado(Nome),
                        situacao: Situacao,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, cadastrarIdentidadeGenero(Dados)];
                case 2:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, alterarIdentidadeGenero(Dados, id !== null && id !== void 0 ? id : '')];
                case 4:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 5:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        navigate(ROUTES.IDENTIDADES_GENERO);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var excluirRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, excluirIdentidadeGenero(id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MENSAGENS.REGISTRO_EXCLUIDO_SUCESSO);
                        navigate(ROUTES.IDENTIDADES_GENERO);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(ROUTES.IDENTIDADES_GENERO, "/").concat(id, "/").concat(MODOS.ALTERAR));
            return [2 /*return*/];
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.IDENTIDADES_GENERO, link_pagina_acima: ROUTES.IDENTIDADES_GENERO }, { children: _jsxs(BoxPrincipal, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsx(TextField, { id: 'id', variant: 'outlined', disabled: true, label: 'C\u00F3digo', value: id, size: 'small' }), _jsx(TextField, { id: 'nome', variant: 'outlined', size: 'small', fullWidth: true, disabled: DESABILITAR_CAMPOS, label: 'Nome', value: Nome, onChange: function (event) { return setNome(event.target.value); }, required: true }), _jsxs(TextField, __assign({ select: true, id: 'situacao' // obrigatorio
                            , label: 'Situa\u00E7\u00E3o' // obrigatorio
                            , size: 'small', fullWidth: true, disabled: DESABILITAR_CAMPOS, placeholder: 'Situa\u00E7\u00E3o', value: Situacao, onChange: function (event) {
                                var value = event.target.value;
                                setSituacao(value);
                            } }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })), _jsxs(BoxBotoes, { children: [(MODOS.ALTERAR == modo || MODOS.CADASTRAR == modo) && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.EXCLUIR == modo && _jsx(BotaoExcluir, { onClick: excluirRegistro }), MODOS.VISUALIZAR == modo && _jsx(BotaoEditar, { onClick: editarRegistro, disabled: !AcoesFuncionalidade['ALTERAR'] }), _jsx(BotaoFechar, { modo: modo })] })] })), loading && _jsx(Loading, {})] }) })));
};
export default IdentidadesGeneroEditar;
