var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
var UnexpectedError = function (_a) {
    var error = _a.error;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var handleClose = function () { return setOpen(false); };
    return (_jsx(Backdrop, __assign({ open: open }, { children: _jsxs(Paper, { children: [_jsx("p", { children: "Something went wrong:" }), _jsx("pre", { children: error.message }), _jsx("br", {}), _jsx(Button, __assign({ variant: 'contained', onClick: handleClose }, { children: "Fechar" }))] }) })));
};
export default UnexpectedError;
