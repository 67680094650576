var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Typography, MenuItem, Grid, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import { MODOS, MENSAGENS } from '../../../../utils';
import { ROUTES, PAGINAS } from '../../../../routes';
import AuthContext from '../../../../contexts/auth';
import { BoxBotoes } from '../../../../components/form_box';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoFechar, BotaoAuxiliar } from '../../../../components/botoes';
import { InputDados } from '../../../../components/input_dados';
import { ModeEditOutlined as ModeEditOutlinedIcon, DeleteOutline as DeleteOutlineIcon, } from '@mui/icons-material';
import GridComp from '../../../../components/grid';
import { alterarContato as alterarContatoAPI, cadastrarContato, excluirContato, getTiposContatos, getReeducandoContatos, STATUS_API } from './services';
export var ReeducandosContatos = function (props) {
    var _a;
    var _b = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _b.DEV, addMensagem = _b.addMensagem, getAcoesFuncionalidade = _b.getAcoesFuncionalidade;
    var _c = React.useState(getAcoesFuncionalidade(PAGINAS.REEDUCANDOS_CONTATOS)), AcoesFuncionalidade = _c[0], setAcoesFuncionalidade = _c[1];
    var navigate = useNavigate();
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState(true), LoadingInicio = _e[0], setLoadingInicio = _e[1];
    var _f = useParams(), id = _f.id, modo = _f.modo;
    var ID = (_a = props.reeducando_id) !== null && _a !== void 0 ? _a : id;
    var _g = useState([]), ContatosReeducando = _g[0], setContatosReeducando = _g[1];
    var _h = useState([]), TiposContatosDisponiveis = _h[0], setTiposContatosDisponiveis = _h[1];
    var _j = useState(-1), TipoContatoSelecionado = _j[0], setTipoContatoSelecionado = _j[1];
    var _k = useState(''), Contato = _k[0], setContato = _k[1];
    var _l = useState(true), PrincipalContato = _l[0], setPrincipalContato = _l[1];
    var _m = useState('Ativo'), Situacao = _m[0], setSituacao = _m[1];
    var _o = useState(false), PopupAberto = _o[0], setPopupAberto = _o[1];
    var _p = useState(), EditarID = _p[0], setEditarID = _p[1];
    var _q = useState(-1), EditarTipoContatoSelecionado = _q[0], setEditarTipoContatoSelecionado = _q[1];
    var _r = useState(''), EditarContato = _r[0], setEditarContato = _r[1];
    var _s = useState(true), EditarPrincipalContato = _s[0], setEditarPrincipalContato = _s[1];
    var _t = useState('Ativo'), EditarSituacao = _t[0], setEditarSituacao = _t[1];
    var setAbaAtiva = props.setAbaAtiva;
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('ID', ID);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, TiposContatosAux, ReeducandoDadosAux, contatos;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!ID) return [3 /*break*/, 2];
                    return [4 /*yield*/, Promise.all([getTiposContatos(), getReeducandoContatos(ID)])];
                case 1:
                    _a = _b.sent(), TiposContatosAux = _a[0], ReeducandoDadosAux = _a[1];
                    if (TiposContatosAux.Status == STATUS_API.ERRO) {
                        alert(TiposContatosAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        setTiposContatosDisponiveis(TiposContatosAux);
                    }
                    if (ReeducandoDadosAux.Status == STATUS_API.ERRO) {
                        alert(ReeducandoDadosAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        contatos = ReeducandoDadosAux.contatos;
                        setContatosReeducando(contatos);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    alert('ID do reeducando não informado');
                    _b.label = 3;
                case 3:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!TipoContatoSelecionado || TipoContatoSelecionado == -1) {
            alert("Tipo de Contato ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!Contato || Contato.trim() == '') {
            alert("Contato ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (Contato.length < 14) {
            alert("Contato ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
            return false;
        }
        return true;
    };
    var validarDadosEditar = function () {
        console.log('EditarContato.length : ' + EditarContato.length);
        if (!EditarTipoContatoSelecionado || EditarTipoContatoSelecionado == -1) {
            alert("Tipo de Contato ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!EditarContato || EditarContato.trim() == '') {
            alert("Contato ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (EditarContato.length < 14) {
            alert("Contato ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
            return false;
        }
        return true;
    };
    var adicionarContato = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, contatos;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        tipo_contato_id: TipoContatoSelecionado,
                        contato: Contato,
                        principal: PrincipalContato,
                        situacao: Situacao,
                    };
                    return [4 /*yield*/, cadastrarContato(Dados, ID !== null && ID !== void 0 ? ID : '')];
                case 1:
                    Resp = _a.sent();
                    //console.log('Resp', JSON.stringify(Resp));
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        contatos = Resp.dados.contatos;
                        setContatosReeducando(contatos !== null && contatos !== void 0 ? contatos : []);
                        limparSelecao();
                    }
                    else {
                        alert(Resp.Mensagem);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var alterarContato = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, ContatosAtualizados;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validarDadosEditar())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        tipo_contato_id: EditarTipoContatoSelecionado,
                        contato: EditarContato,
                        principal: EditarPrincipalContato,
                        situacao: EditarSituacao,
                    };
                    return [4 /*yield*/, alterarContatoAPI(Dados, id !== null && id !== void 0 ? id : '', EditarID ? EditarID.toString() : '')];
                case 1:
                    Resp = _a.sent();
                    //console.log('Resp', JSON.stringify(Resp));
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        ContatosAtualizados = ContatosReeducando.map(function (Item) {
                            if (Item.id == EditarID) {
                                return __assign(__assign({}, Item), { tipo_contato_id: EditarTipoContatoSelecionado, contato: EditarContato, principal: EditarPrincipalContato, situacao: EditarSituacao });
                            }
                            else {
                                if (EditarPrincipalContato) {
                                    return __assign(__assign({}, Item), { principal: false });
                                }
                                else {
                                    return Item;
                                }
                            }
                        });
                        setContatosReeducando(ContatosAtualizados);
                        setPopupAberto(false);
                        limparPopup();
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
        var Resp, ContatosAtualizados;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO)) return [3 /*break*/, 2];
                    setLoading(true);
                    return [4 /*yield*/, excluirContato(ID)];
                case 1:
                    Resp = _a.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        ContatosAtualizados = ContatosReeducando.filter(function (Item) { return Item.id != +ID; });
                        setContatosReeducando(ContatosAtualizados);
                    }
                    else {
                        setLoading(false);
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var limparSelecao = function () {
        setTipoContatoSelecionado(-1);
        setContato('');
        setPrincipalContato(false);
        setSituacao('Ativo');
    };
    var limparPopup = function () {
        setEditarID(undefined);
        setEditarTipoContatoSelecionado(-1);
        setEditarContato('');
        setEditarPrincipalContato(false);
        setEditarSituacao('Ativo');
    };
    var COLUNAS_TERMOS = [
        {
            field: 'tipo_contato_id',
            headerName: 'Tipo de Contato',
            width: 150,
            valueGetter: function (value, row) { var _a; return (_a = row.tipo_contato) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'contato',
            headerName: 'Contato',
            width: 150,
        },
        {
            field: 'principal',
            headerName: 'Principal',
            width: 150,
            valueGetter: function (value, row) { return (row.principal ? 'Sim' : ''); },
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            width: 100,
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 80,
            renderCell: function (params) { return (_jsxs(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: [_jsx(Box, __assign({ bgcolor: 'primary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                                var _a;
                                abrirPopup((_a = params.row) === null || _a === void 0 ? void 0 : _a.id);
                            }, disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['ALTERAR'] }, { children: _jsx(ModeEditOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })), _jsx(Box, __assign({ bgcolor: 'error.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () { var _a; return handleDelete((_a = params.row) === null || _a === void 0 ? void 0 : _a.id); }, disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['EXCLUIR'] }, { children: _jsx(DeleteOutlineIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) }))] }))); },
        },
    ];
    var abrirPopup = function (ContatoID) { return __awaiter(void 0, void 0, void 0, function () {
        var ContatoAux, id_1, tipo_contato_id, contato, principal, situacao;
        return __generator(this, function (_a) {
            setLoading(true);
            ContatoAux = ContatosReeducando.find(function (Item) { return Item.id == +ContatoID; });
            if (ContatoAux) {
                id_1 = ContatoAux.id, tipo_contato_id = ContatoAux.tipo_contato_id, contato = ContatoAux.contato, principal = ContatoAux.principal, situacao = ContatoAux.situacao;
                setEditarID(id_1);
                setEditarTipoContatoSelecionado(tipo_contato_id);
                setEditarContato(contato);
                setEditarPrincipalContato(principal);
                setEditarSituacao(situacao);
            }
            setLoading(false);
            setPopupAberto(true);
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        if (confirm('Tem certeza que deseja sair?')) {
            setPopupAberto(false);
        }
    };
    var getPrecisaMascaraCelular = function (TipoContatoSelecionadoParm) {
        var _a;
        if (TipoContatoSelecionadoParm === void 0) { TipoContatoSelecionadoParm = TipoContatoSelecionado; }
        var TipoContatoNome = (_a = TiposContatosDisponiveis.find(function (item) { return item.id == TipoContatoSelecionadoParm; })) === null || _a === void 0 ? void 0 : _a.nome;
        if (!TipoContatoNome)
            return false;
        return TipoContatoNome.toUpperCase() == 'CELULAR' || TipoContatoNome.toUpperCase() == 'WHATSAPP' || TipoContatoNome.toUpperCase() == 'WHATS';
    };
    return (_jsx(_Fragment, { children: !LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ mt: 5 }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Contato" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-contato', label: 'Tipo de Contato', placeholder: 'Tipo de Contato', value: TipoContatoSelecionado, onChange: function (event) {
                                            setTipoContatoSelecionado(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposContatosDisponiveis &&
                                                TiposContatosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'contato', label: 'Contato', mask: getPrecisaMascaraCelular() ? '(99) 99999-9999' : '(99) 9999-9999', value: Contato, onChange: function (event) {
                                            console.log('Contato.length : ' + Contato.replace(/\D/g, '').length);
                                            setContato(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'principal', label: 'Principal', placeholder: 'Principal', value: PrincipalContato, onChange: function (event) {
                                            var Valor = event.target.value;
                                            setPrincipalContato(Valor == undefined ? undefined : Valor == 'true');
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), _jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: Situacao, onChange: function (event) {
                                            setSituacao(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: [_jsx(BotaoAuxiliar, __assign({ onClick: limparSelecao, backgroundColor: 'error.main', disabled: DESABILITAR_CAMPOS }, { children: "Limpar" })), _jsx(BotaoAuxiliar, __assign({ sx: { ml: 1 }, onClick: adicionarContato, backgroundColor: 'principal.main', disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['CADASTRAR'] }, { children: "Adicionar" }))] }))] })), _jsx(Box, __assign({ mt: 8 }, { children: _jsx(GridComp, { rows: ContatosReeducando, columns: COLUNAS_TERMOS, loading: loading, paginationMode: 'client', onPaginationModelChange: function () { } }) })), _jsxs(BoxBotoes, __assign({ sx: { marginTop: 10 } }, { children: [_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'terceary.main', onClick: function () { return (setAbaAtiva ? setAbaAtiva(1) : props.fecharPopUpCadastrarContato ? props.fecharPopUpCadastrarContato(ContatosReeducando) : {}); } }, { children: "Voltar" })), !props.popup && _jsx(BotaoFechar, { modo: modo, pagina: ROUTES.REEDUCANDOS })] }))] })), _jsxs(Dialog, __assign({ open: PopupAberto, onClose: handleClose, maxWidth: 'sm', fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Editar Contato" }), _jsxs(DialogContent, __assign({ style: { margin: 5, padding: 10 } }, { children: [_jsx(Box, __assign({ mb: 2, mt: 2 }, { children: _jsx(InputDados, { ID: 'ID', label: 'ID', value: EditarID, disabled: true }) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'editar-tipo-contato', label: 'Tipo de Contato', placeholder: 'Tipo de Contato', value: EditarTipoContatoSelecionado, onChange: function (event) {
                                            setEditarTipoContatoSelecionado(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposContatosDisponiveis &&
                                                TiposContatosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsx(InputDados, { id: 'editar-contato', label: 'Contato', mask: getPrecisaMascaraCelular(EditarTipoContatoSelecionado) ? '(99) 99999-9999' : '(99) 9999-9999', value: EditarContato, onChange: function (event) { return setEditarContato(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'editar-principal', label: 'Principal', placeholder: 'Principal', value: EditarPrincipalContato, onChange: function (event) {
                                            var Valor = event.target.value;
                                            setEditarPrincipalContato(Valor == undefined ? undefined : Valor == 'true');
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), _jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'editar-situacao', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: EditarSituacao, onChange: function (event) {
                                            setEditarSituacao(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) }))] })), _jsxs(DialogActions, __assign({ style: { padding: 20 } }, { children: [_jsx(Button, __assign({ onClick: handleClose }, { children: "Cancelar" })), _jsx(Button, __assign({ onClick: alterarContato, variant: 'contained', color: 'primary' }, { children: "Salvar" }))] }))] }))] })) }));
};
export default ReeducandosContatos;
