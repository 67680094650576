import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
export var BoxPrincipal = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({ width: '100%' });
});
export var BoxSuperior = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        justifyContent: 'space-around',
    });
});
export var BoxGrid = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        marginTop: 20,
    });
});
export var BoxInferior = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({});
});
