var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Typography, MenuItem, Grid, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import { MODOS, MENSAGENS } from '../../../../utils';
import { ROUTES, PAGINAS } from '../../../../routes';
import AuthContext from '../../../../contexts/auth';
import { BoxBotoes } from '../../../../components/form_box';
import Loading from '../../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoFechar, BotaoAuxiliar } from '../../../../components/botoes';
import { InputDados } from '../../../../components/input_dados';
import { ModeEditOutlined as ModeEditOutlinedIcon, DeleteOutline as DeleteOutlineIcon, } from '@mui/icons-material';
import GridComp from '../../../../components/grid';
import { alterarDependencia as alterarDependenciaAPI, cadastrarDependencia, excluirDependencia, getTiposDependencias, getTiposTempoUso, getReeducandoDependencias, STATUS_API, } from './services';
export var ReeducandosDependencias = function (props) {
    var _a = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _a.DEV, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.REEDUCANDOS_DEPENDENCIAS)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState([]), DependenciasReeducando = _f[0], setDependenciasReeducando = _f[1];
    var _g = useState([]), TiposDependenciasDisponiveis = _g[0], setTiposDependenciasDisponiveis = _g[1];
    var _h = useState(-1), TipoDependenciaSelecionado = _h[0], setTipoDependenciaSelecionado = _h[1];
    var _j = useState(-1), TipoTempoSelecionado = _j[0], setTipoTempoSelecionado = _j[1];
    var _k = useState(), TempoUso = _k[0], setTempoUso = _k[1];
    var _l = useState(false), RecaidaRecente = _l[0], setRecaidaRecente = _l[1];
    var _m = useState(false), PopupAberto = _m[0], setPopupAberto = _m[1];
    var _o = useState(), EditarID = _o[0], setEditarID = _o[1];
    var _p = useState(-1), EditarTipoDependenciaSelecionado = _p[0], setEditarTipoDependenciaSelecionado = _p[1];
    var _q = useState(-1), EditarTipoTempoSelecionado = _q[0], setEditarTipoTempoSelecionado = _q[1];
    var _r = useState(), EditarTempoUso = _r[0], setEditarTempoUso = _r[1];
    var _s = useState(true), EditarRecaidaRecente = _s[0], setEditarRecaidaRecente = _s[1];
    var setAbaAtiva = props.setAbaAtiva;
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, TiposDependenciasAux, ReeducandoDadosAux, dependencias;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, Promise.all([getTiposDependencias(), getReeducandoDependencias(id)])];
                case 1:
                    _a = _b.sent(), TiposDependenciasAux = _a[0], ReeducandoDadosAux = _a[1];
                    if (TiposDependenciasAux.Status == STATUS_API.ERRO) {
                        alert(TiposDependenciasAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        setTiposDependenciasDisponiveis(TiposDependenciasAux);
                    }
                    if (ReeducandoDadosAux.Status == STATUS_API.ERRO) {
                        alert(ReeducandoDadosAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        dependencias = ReeducandoDadosAux.dependencias;
                        setDependenciasReeducando(dependencias);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    alert('ID do reeducando não informado');
                    _b.label = 3;
                case 3:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!TipoDependenciaSelecionado || TipoDependenciaSelecionado == -1) {
            alert("Tipo de Depend\u00EAncia ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var validarDadosEditar = function () {
        if (!EditarTipoDependenciaSelecionado || EditarTipoDependenciaSelecionado == -1) {
            alert("Tipo de Depend\u00EAncia ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var adicionarDependencia = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, dependencias;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        tipo_dependencia_id: TipoDependenciaSelecionado,
                        tempo_uso: TempoUso ? parseInt(TempoUso) : null,
                        tempo_uso_tipo: TipoTempoSelecionado,
                        recaida_recente: RecaidaRecente,
                    };
                    return [4 /*yield*/, cadastrarDependencia(Dados, id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    //console.log('Resp', JSON.stringify(Resp));
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        dependencias = Resp.dados.dependencias;
                        setDependenciasReeducando(dependencias !== null && dependencias !== void 0 ? dependencias : []);
                        limparSelecao();
                    }
                    else {
                        alert(Resp.Mensagem);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var alterarDependencia = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, DependenciasAtualizados;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validarDadosEditar())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        tipo_dependencia_id: EditarTipoDependenciaSelecionado,
                        tempo_uso: EditarTempoUso ? parseInt(EditarTempoUso) : null,
                        tempo_uso_tipo: EditarTipoTempoSelecionado,
                        recaida_recente: EditarRecaidaRecente,
                    };
                    return [4 /*yield*/, alterarDependenciaAPI(Dados, EditarID ? EditarID.toString() : '')];
                case 1:
                    Resp = _a.sent();
                    //console.log('Resp', JSON.stringify(Resp));
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        DependenciasAtualizados = DependenciasReeducando.map(function (Item) {
                            if (Item.id == EditarID) {
                                return __assign(__assign({}, Item), Dados);
                            }
                            else {
                                return Item;
                            }
                        });
                        setDependenciasReeducando(DependenciasAtualizados);
                        setPopupAberto(false);
                        limparPopup();
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var Resp, DependenciasAtualizados;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO)) return [3 /*break*/, 2];
                    setLoading(true);
                    return [4 /*yield*/, excluirDependencia(id)];
                case 1:
                    Resp = _a.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        DependenciasAtualizados = DependenciasReeducando.filter(function (Item) { return Item.id != +id; });
                        setDependenciasReeducando(DependenciasAtualizados);
                    }
                    else {
                        setLoading(false);
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var limparSelecao = function () {
        setTipoDependenciaSelecionado(-1);
        setRecaidaRecente(false);
        setTempoUso('');
        setTipoTempoSelecionado(-1);
    };
    var limparPopup = function () {
        setEditarID(undefined);
        setEditarTipoDependenciaSelecionado(-1);
        setEditarRecaidaRecente(false);
        setEditarTempoUso('');
        setEditarTipoTempoSelecionado(-1);
    };
    var COLUNAS_TERMOS = [
        {
            field: 'tipo_dependencia_id',
            headerName: 'Tipo de Dependência',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.tipo_dependencia) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'tempo_uso',
            headerName: 'Tempo de Uso',
            width: 150,
        },
        {
            field: 'tempo_uso_tipo',
            headerName: 'Tipo de Tempo de Uso',
            width: 150,
        },
        {
            field: 'recaida_recente',
            headerName: 'Recaída Recente',
            width: 150,
            valueGetter: function (value, row) { return (value ? 'Sim' : 'Não'); },
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 80,
            renderCell: function (params) { return (_jsxs(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: [_jsx(Box, __assign({ bgcolor: 'primary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                                var _a;
                                abrirPopup((_a = params.row) === null || _a === void 0 ? void 0 : _a.id);
                            }, disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['ALTERAR'] }, { children: _jsx(ModeEditOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })), _jsx(Box, __assign({ bgcolor: 'error.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () { var _a; return handleDelete((_a = params.row) === null || _a === void 0 ? void 0 : _a.id); }, disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['EXCLUIR'] }, { children: _jsx(DeleteOutlineIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) }))] }))); },
        },
    ];
    var abrirPopup = function (DependenciaID) { return __awaiter(void 0, void 0, void 0, function () {
        var DependenciaAux, id_1, tipo_dependencia_id, tempo_uso, tempo_uso_tipo, recaida_recente;
        return __generator(this, function (_a) {
            setLoading(true);
            DependenciaAux = DependenciasReeducando.find(function (Item) { return Item.id == +DependenciaID; });
            if (DependenciaAux) {
                id_1 = DependenciaAux.id, tipo_dependencia_id = DependenciaAux.tipo_dependencia_id, tempo_uso = DependenciaAux.tempo_uso, tempo_uso_tipo = DependenciaAux.tempo_uso_tipo, recaida_recente = DependenciaAux.recaida_recente;
                setEditarID(id_1);
                setEditarTipoDependenciaSelecionado(tipo_dependencia_id);
                setEditarTempoUso(tempo_uso);
                setEditarTipoTempoSelecionado(tempo_uso_tipo);
                setEditarRecaidaRecente(recaida_recente);
            }
            setLoading(false);
            setPopupAberto(true);
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        if (confirm('Tem certeza que deseja sair?')) {
            setPopupAberto(false);
        }
    };
    return (_jsx(_Fragment, { children: !LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ mt: 5 }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Depend\u00EAncia" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-dependencia', label: 'Tipo de Depend\u00EAncia', placeholder: 'Tipo de Depend\u00EAncia', value: TipoDependenciaSelecionado, onChange: function (event) {
                                            setTipoDependenciaSelecionado(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposDependenciasDisponiveis &&
                                                TiposDependenciasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsx(InputDados, { id: 'tempo-uso', label: 'Tempo de Uso', mask: '9999', value: TempoUso, onChange: function (event) { return setTempoUso(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-tempo-uso', label: 'Tipo de Tempo de Uso', placeholder: 'Tipo de Tempo de Uso', value: TipoTempoSelecionado, onChange: function (event) {
                                            setTipoTempoSelecionado(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), getTiposTempoUso().map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'recaida-recente', label: 'Reca\u00EDda Recente', placeholder: 'Reca\u00EDda Recente', value: RecaidaRecente, onChange: function (event) {
                                            var Valor = event.target.value;
                                            setRecaidaRecente(Valor == undefined ? undefined : Valor == 'true');
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), _jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: [_jsx(BotaoAuxiliar, __assign({ onClick: limparSelecao, backgroundColor: 'error.main', disabled: DESABILITAR_CAMPOS }, { children: "Limpar" })), _jsx(BotaoAuxiliar, __assign({ sx: { ml: 1 }, onClick: adicionarDependencia, backgroundColor: 'principal.main', disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['CADASTRAR'] }, { children: "Adicionar" }))] }))] })), _jsx(Box, __assign({ mt: 8 }, { children: _jsx(GridComp, { rows: DependenciasReeducando, columns: COLUNAS_TERMOS, loading: loading, paginationMode: 'client', onPaginationModelChange: function () { } }) })), _jsxs(BoxBotoes, __assign({ sx: { marginTop: 10 } }, { children: [_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'terceary.main', onClick: function () { return setAbaAtiva(5); } }, { children: "Voltar" })), _jsx(BotaoFechar, { modo: modo, pagina: ROUTES.REEDUCANDOS })] }))] })), _jsxs(Dialog, __assign({ open: PopupAberto, onClose: handleClose, maxWidth: 'sm', fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Editar Depend\u00EAncia" }), _jsxs(DialogContent, __assign({ style: { margin: 5, padding: 10 } }, { children: [_jsx(Box, __assign({ mb: 2, mt: 2 }, { children: _jsx(InputDados, { id: 'id', label: 'ID', value: EditarID, disabled: true }) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'editar-tipo-dependencia', label: 'Tipo de Depend\u00EAncia', placeholder: 'Tipo de Depend\u00EAncia', value: EditarTipoDependenciaSelecionado, onChange: function (event) {
                                            setEditarTipoDependenciaSelecionado(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposDependenciasDisponiveis &&
                                                TiposDependenciasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsx(InputDados, { id: 'editar-tempo-uso', label: 'Tempo de Uso', mask: '9999', value: EditarTempoUso, onChange: function (event) { return setEditarTempoUso(event.target.value); }, disabled: DESABILITAR_CAMPOS }) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'editar-tipo-tempo-uso', label: 'Tipo de Tempo de Uso', placeholder: 'Tipo de Tempo de Uso', value: EditarTipoTempoSelecionado, onChange: function (event) {
                                            setEditarTipoTempoSelecionado(event.target.value);
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), getTiposTempoUso().map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'editar-recaida-recente', label: 'Reca\u00EDda Recente', placeholder: 'Reca\u00EDda Recente', value: EditarRecaidaRecente, onChange: function (event) {
                                            var Valor = event.target.value;
                                            setEditarRecaidaRecente(Valor == undefined ? undefined : Valor == 'true');
                                        }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: _jsx("em", { children: "Selecione" }) })), _jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) }))] })), _jsxs(DialogActions, __assign({ style: { padding: 20 } }, { children: [_jsx(Button, __assign({ onClick: handleClose }, { children: "Cancelar" })), _jsx(Button, __assign({ onClick: alterarDependencia, variant: 'contained', color: 'primary' }, { children: "Salvar" }))] }))] })), loading && _jsx(Loading, {})] })) }));
};
export default ReeducandosDependencias;
