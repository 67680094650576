var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Tooltip, MenuItem, Grid, DialogActions, DialogContent, Dialog, DialogTitle, } from '@mui/material';
import { getCodigoOfertaFormatada, MODOS } from '../../utils';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { ROUTES, PAGINAS } from '../../routes';
import { BotaoAdicionar } from '../../components/botoes';
import BarraPesquisar from '../../components/grid_pesquisar';
import GridComp from '../../components/grid';
import { PaginationModelDefault } from '../../components/grid';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../components/grid_box';
import { getOfertasVagasTrabalhos, getCombosListarVagas } from './selecao_profissionais.service';
import { STATUS_API } from '../../api';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import InputDados from '../../components/input_dados';
export var SelecaoProfissionaisListar = function (props) {
    var _a;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var navigate = useNavigate();
    var _c = useState([]), InstituicoesDisponiveis = _c[0], setInstituicoesDisponiveis = _c[1];
    var _d = useState(-1), BuscaInstituicaoSelecionada = _d[0], setBuscaInstituicaoSelecionada = _d[1];
    var _e = useState([]), PerfisLaboraisDisponiveis = _e[0], setPerfisLaboraisDisponiveis = _e[1];
    var _f = useState(-1), BuscaPerfilLaboralSelecionado = _f[0], setBuscaPerfilLaboralSelecionado = _f[1];
    var _g = useState([]), OfertasVagasDisponiveis = _g[0], setOfertasVagasDisponiveis = _g[1];
    var _h = useState(-1), BuscaOfertaVagaSelecionado = _h[0], setBuscaOfertaVagaSelecionado = _h[1];
    var _j = useState([]), TiposStatusVagasDisponiveis = _j[0], setTiposStatusVagasDisponiveis = _j[1];
    var _k = useState('-1'), BuscaStatusOfertasSelecionado = _k[0], setBuscaStatusOfertasSelecionado = _k[1];
    var _l = useState(''), BuscaDataInicio = _l[0], setBuscaDataInicio = _l[1];
    var _m = useState(''), BuscaDataFim = _m[0], setBuscaDataFim = _m[1];
    var _o = useState(''), TextoBusca = _o[0], setTextoBusca = _o[1];
    var _p = useState([]), OfertasVagaTrabalhos = _p[0], setOfertasVagaTrabalhos = _p[1];
    var _q = useState(0), TotalRegistros = _q[0], setTotalRegistros = _q[1];
    var _r = React.useState(PaginationModelDefault), PaginationModel = _r[0], setPaginationModel = _r[1];
    var _s = useState(false), PopupAberto = _s[0], setPopupAberto = _s[1];
    var _t = useState(), OfertaSelecionada = _t[0], setOfertaSelecionada = _t[1];
    var _u = useContext(AuthContext) || { getAcoesFuncionalidade: function () { return []; }, getFuncionalidadeExiste: function () { return false; } }, getAcoesFuncionalidade = _u.getAcoesFuncionalidade, getFuncionalidadeExiste = _u.getFuncionalidadeExiste;
    var _v = React.useState(getAcoesFuncionalidade(PAGINAS.SELECAO_PROFISSIONAIS)), AcoesFuncionalidade = _v[0], setAcoesFuncionalidade = _v[1];
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusOfertasSelecionadoParm === void 0) { BuscaStatusOfertasSelecionadoParm = BuscaStatusOfertasSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaPerfilLaboralSelecionadoParm === void 0) { BuscaPerfilLaboralSelecionadoParm = BuscaPerfilLaboralSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var TipoRegime, CombosVagas, ofertas_vagas, instituicoes, perfis_laborais, status_ofertas, Resposta, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        switch (true) {
                            case getFuncionalidadeExiste(PAGINAS.ATENDIMENTOS_REGIME_FECHADO) && getFuncionalidadeExiste(PAGINAS.ATENDIMENTOS_REGIME_SEMIABERTO):
                                TipoRegime = null;
                                break;
                            case getFuncionalidadeExiste(PAGINAS.ATENDIMENTOS_REGIME_FECHADO):
                                TipoRegime = 'Fechado';
                                break;
                            case getFuncionalidadeExiste(PAGINAS.ATENDIMENTOS_REGIME_SEMIABERTO):
                                TipoRegime = 'Semiaberto';
                                break;
                            default:
                                {
                                    alert('Sem permissão de Regimes para a funcionalidade de Seleção de Profissionais');
                                    return [2 /*return*/];
                                }
                                break;
                        }
                        if (!(InstituicoesDisponiveis.length <= 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCombosListarVagas()];
                    case 1:
                        CombosVagas = _a.sent();
                        if (CombosVagas.Status == STATUS_API.ERRO) {
                            alert(CombosVagas.Mensagem);
                            setIsLoading(false);
                            return [2 /*return*/];
                        }
                        ofertas_vagas = CombosVagas.ofertas_vagas, instituicoes = CombosVagas.instituicoes, perfis_laborais = CombosVagas.perfis_laborais, status_ofertas = CombosVagas.status_ofertas;
                        setOfertasVagasDisponiveis(ofertas_vagas);
                        setTiposStatusVagasDisponiveis(status_ofertas);
                        setInstituicoesDisponiveis(instituicoes);
                        setPerfisLaboraisDisponiveis(perfis_laborais);
                        _a.label = 2;
                    case 2: return [4 /*yield*/, getOfertasVagasTrabalhos(TextoBuscaParm == '' ? null : TextoBuscaParm, BuscaStatusOfertasSelecionadoParm == '-1' ? null : BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm == -1 ? null : BuscaInstituicaoSelecionadaParm === null || BuscaInstituicaoSelecionadaParm === void 0 ? void 0 : BuscaInstituicaoSelecionadaParm.toString(), BuscaPerfilLaboralSelecionadoParm == -1 ? null : BuscaPerfilLaboralSelecionadoParm === null || BuscaPerfilLaboralSelecionadoParm === void 0 ? void 0 : BuscaPerfilLaboralSelecionadoParm.toString(), BuscaDataInicioParm == '' ? null : BuscaDataInicioParm, BuscaDataFimParm == '' ? null : BuscaDataFimParm, TipoRegime, PaginationModelParm)];
                    case 3:
                        Resposta = _a.sent();
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setOfertasVagaTrabalhos(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var filtrarGrid = function (TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusOfertasSelecionadoParm === void 0) { BuscaStatusOfertasSelecionadoParm = BuscaStatusOfertasSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaPerfilLaboralSelecionadoParm === void 0) { BuscaPerfilLaboralSelecionadoParm = BuscaPerfilLaboralSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        setIsLoading(true);
        setTextoBusca(TextoBuscaParm);
        setBuscaStatusOfertasSelecionado(BuscaStatusOfertasSelecionadoParm);
        setBuscaInstituicaoSelecionada(BuscaInstituicaoSelecionadaParm);
        setBuscaPerfilLaboralSelecionado(BuscaPerfilLaboralSelecionadoParm);
        setBuscaDataInicio(BuscaDataInicioParm);
        setBuscaDataFim(BuscaDataFimParm);
        resetGrid(TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModel);
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, BuscaDataInicio, BuscaDataFim, PaginationModelParm);
    };
    var resetGrid = function (TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusOfertasSelecionadoParm === void 0) { BuscaStatusOfertasSelecionadoParm = BuscaStatusOfertasSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaPerfilLaboralSelecionadoParm === void 0) { BuscaPerfilLaboralSelecionadoParm = BuscaPerfilLaboralSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        carregarDados(TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var COLUNAS_OFERTAS = [
        {
            field: 'id',
            headerName: 'Código',
            description: 'Código da Oferta',
            width: 100,
            valueGetter: function (value, row) { return "".concat(getCodigoOfertaFormatada(row.id)); },
        },
        {
            field: 'descricao',
            headerName: 'Descrição',
            description: 'Descrição da Oferta',
            width: 200,
        },
        {
            field: 'instituicao',
            headerName: 'Instituição',
            width: 250,
            valueGetter: function (value, row) { var _a, _b; return (_b = (_a = row.instituicao) === null || _a === void 0 ? void 0 : _a.dados) === null || _b === void 0 ? void 0 : _b.razao_social; },
        },
        {
            field: 'vagas_qtd',
            headerName: 'QTD',
            description: 'Quantidade de Vagas Solicitadas',
            width: 100,
        },
        {
            field: 'situacao_oferta',
            headerName: 'Status',
            description: 'Status da Oferta',
            width: 150,
            valueGetter: function (value, row) { return value; },
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 80,
            renderCell: function (params) { return (_jsxs(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: [_jsx(Tooltip, __assign({ title: 'Visualizar Vagas', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'primary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () { return abrirPopup(params.row); } }, { children: _jsx(VisibilityOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) })), _jsx(Tooltip, __assign({ title: 'Visualizar', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'terceary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                                    navigate("".concat(ROUTES.SELECAO_PROFISSIONAIS_VAGAS, "/").concat(params.row.id, "/").concat(MODOS.VISUALIZAR));
                                } }, { children: _jsx(AssignmentIndIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) })), _jsx(Tooltip, __assign({ title: 'Alterar', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'secondary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                                    navigate("".concat(ROUTES.OFERTAS_VAGAS_TRABALHO, "/").concat(params.row.id, "/").concat(MODOS.ALTERAR));
                                } }, { children: _jsx(ModeEditOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))] }))); },
        },
    ];
    var COLUNAS_VAGAS = [
        {
            field: 'id',
            headerName: 'Código',
            description: 'Código da Vaga',
            width: 100,
            valueGetter: function (value, row) { return "".concat(getCodigoOfertaFormatada(row.id)); },
        },
        {
            field: 'descricao',
            headerName: 'Descrição',
            description: 'Descrição da Vaga',
            width: 250,
        },
        {
            field: 'perfil_laboral',
            headerName: 'Perfil',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.perfil_laboral) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'salario',
            headerName: 'Salário',
            width: 100,
        },
        {
            field: 'quantidade_vagas',
            headerName: 'QTD',
            description: 'Quantidade de Vagas',
            width: 100,
        },
        {
            field: 'tipo_status_vaga',
            headerName: 'Status',
            description: 'Status da Vaga',
            width: 250,
            valueGetter: function (value, row) { return value; },
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.SELECAO_PROFISSIONAIS_VAGAS,
        VISUALIZAR: false,
        ALTERAR: false,
    };
    var abrirPopup = function (Oferta) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setOfertaSelecionada(Oferta);
            setPopupAberto(true);
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        setPopupAberto(false);
    };
    return (_jsxs(ContainerPainel, __assign({ pagina: PAGINAS.SELECAO_PROFISSIONAIS }, { children: [_jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), _jsx(BotaoAdicionar, { pagina: ROUTES.SELECAO_PROFISSIONAIS, disabled: !AcoesFuncionalidade[MODOS.CADASTRAR] })] }), _jsxs(Grid, __assign({ container: true, spacing: 1, mt: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2.4 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'status_vagas' // obrigatorio
                                    , label: 'Status Ofertas' // obrigatorio
                                    , placeholder: 'Status Ofertas', value: BuscaStatusOfertasSelecionado, onChange: function (event) {
                                        filtrarGrid(TextoBusca, event.target.value, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, BuscaDataInicio, BuscaDataFim);
                                    }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), TiposStatusVagasDisponiveis &&
                                            TiposStatusVagasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.nome }, { children: item.nome }), item.nome)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 2.4 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'instituicao' // obrigatorio
                                    , label: 'Institui\u00E7\u00E3o' // obrigatorio
                                    , placeholder: 'Institui\u00E7\u00E3o', value: BuscaInstituicaoSelecionada, onChange: function (event) {
                                        //const ValorAux = event.target.value;
                                        filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, event.target.value, BuscaPerfilLaboralSelecionado, BuscaDataInicio, BuscaDataFim);
                                    }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), InstituicoesDisponiveis &&
                                            InstituicoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.dados.razao_social }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 2.4 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'perfil_laboral' // obrigatorio
                                    , label: 'Perfil Laboral' // obrigatorio
                                    , placeholder: 'Perfil Laboral', value: BuscaPerfilLaboralSelecionado, onChange: function (event) {
                                        filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, event.target.value, BuscaDataInicio, BuscaDataFim);
                                    }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), PerfisLaboraisDisponiveis &&
                                            PerfisLaboraisDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsxs(Grid, __assign({ item: true, xs: 2.4 }, { children: [' ', _jsx(InputDados, { id: 'data_inicio', label: 'Data In\u00EDcio', mask: '99/99/9999', value: BuscaDataInicio, onChange: function (event) {
                                            setBuscaDataInicio(event.target.value);
                                        }, onBlur: function (event) {
                                            filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, event.target.value, BuscaDataFim);
                                        }, size: 'small' })] })), _jsx(Grid, __assign({ item: true, xs: 2.4 }, { children: _jsx(InputDados, { id: 'data_fim', label: 'Data Fim', mask: '99/99/9999', value: BuscaDataFim, onChange: function (event) {
                                        setBuscaDataFim(event.target.value);
                                    }, onBlur: function (event) {
                                        filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, BuscaDataInicio, event.target.value);
                                    }, size: 'small' }) }))] })), _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_OFERTAS, rows: OfertasVagaTrabalhos, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange, initialState: {
                                sorting: {
                                    sortModel: [{ field: 'id', sort: 'asc' }],
                                },
                            } }) })] }), _jsxs(Dialog, __assign({ open: PopupAberto, onClose: handleClose, maxWidth: 'lg' }, { children: [_jsx(DialogTitle, { children: "Vagas - Oferta ".concat(getCodigoOfertaFormatada(OfertaSelecionada === null || OfertaSelecionada === void 0 ? void 0 : OfertaSelecionada.id)) }), _jsx(DialogContent, __assign({ style: { margin: 5, padding: 10 } }, { children: _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_VAGAS, rows: OfertaSelecionada === null || OfertaSelecionada === void 0 ? void 0 : OfertaSelecionada.vagas, TotalRegistros: (_a = OfertaSelecionada === null || OfertaSelecionada === void 0 ? void 0 : OfertaSelecionada.vagas) === null || _a === void 0 ? void 0 : _a.length, isLoading: isLoading, initialState: {
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'asc' }],
                                    },
                                } }) }) })), _jsx(DialogActions, __assign({ style: { padding: 20 } }, { children: _jsx(Button, __assign({ onClick: handleClose }, { children: "Cancelar" })) }))] }))] })));
};
export default SelecaoProfissionaisListar;
