var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import RoutesConfig from './routes';
import UnexpectedError from './resources/UnexpectedError';
import { CircularProgress } from '@mui/material';
import { AuthProvider } from './contexts/auth';
import { NotificacoesProvider } from './contexts/notificacoes';
var Root = function () {
    return (_jsx(Suspense, __assign({ fallback: _jsx(CircularProgress, {}) }, { children: _jsx(ErrorBoundary, __assign({ FallbackComponent: UnexpectedError }, { children: _jsx(AuthProvider, { children: _jsx(NotificacoesProvider, { children: _jsx(Routes, { children: RoutesConfig.map(function (_a) {
                            var path = _a.path, element = _a.element, args = __rest(_a, ["path", "element"]);
                            var Element = element;
                            return _jsx(Route, __assign({ path: path, element: _jsx(Element, {}) }, args), path);
                        }) }) }) }) })) })));
};
export default Root;
