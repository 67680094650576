import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
export var BoxPrincipal = styled(FormControl)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '50%',
        minWidth: 350,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        paddingBottom: 30,
    });
});
export var BoxBotoes = styled(FormControl)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        marginTop: 20,
    });
});
