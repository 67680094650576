var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Box, MenuItem } from '@mui/material';
import InputDados from '../input_dados';
var StatusPesquisar = function (props) {
    var StatusPesquisa = props.StatusPesquisa, filtrarGrid = props.filtrarGrid, disabled = props.disabled;
    return (_jsx(Box, __assign({ p: 2, pl: 0, sx: { width: 150 } }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao' // obrigatorio
            , label: 'Situa\u00E7\u00E3o' // obrigatorio
            , placeholder: 'Situa\u00E7\u00E3o', value: StatusPesquisa, onChange: function (event) {
                filtrarGrid(null, event.target.value);
            }, size: 'small', fullWidth: true, disabled: true }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), _jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })));
};
export default StatusPesquisar;
