var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { CssBaseline, Typography, Box } from '@mui/material';
import HeaderMobile from '../header_mobile';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { FONT_SIZE, MODOS } from '../../utils';
var ContainerModal = function (props) {
    var children = props.children, pagina = props.pagina, pagina_acima = props.pagina_acima, link_pagina_acima = props.link_pagina_acima, modo = props.modo;
    return (_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(CssBaseline, {}), _jsx(HeaderMobile, { pagina: (pagina !== null && pagina !== void 0 ? pagina : pagina_acima) || '', modo: modo !== null && modo !== void 0 ? modo : MODOS.LISTAR }), _jsx(Box, __assign({ sx: { display: 'flex', minHeight: '80dvh' } }, { children: _jsxs(Box, __assign({ component: 'main', className: 'MainContent', sx: {
                        px: { xs: 2, md: 6 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '80dvh',
                        gap: 1,
                    } }, { children: [_jsx(Box, __assign({ sx: {
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'start', sm: 'center' },
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            } }, { children: _jsx(Typography, __assign({ component: 'h1', style: { fontWeight: 'bold', fontSize: FONT_SIZE.extraLarge, textTransform: modo ? 'capitalize' : 'none' } }, { children: modo !== null && modo !== void 0 ? modo : pagina })) })), _jsx(Box, __assign({ sx: { margin: '0%', display: 'flex', justifyContent: 'center' } }, { children: children }))] })) }))] })));
};
export default ContainerModal;
