var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
var NotFoundPage = function () {
    var navigate = useNavigate();
    /**
     * Call this function to redirect the user to the homepage.
     */
    var redirectToHomePage = function () {
        navigate(ROUTES.HOME_SITE);
    };
    return (_jsxs("div", __assign({ style: { position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' } }, { children: [_jsx("h1", __assign({ style: { fontSize: '4em' } }, { children: "Oops 404!" })), _jsx("span", __assign({ style: { cursor: 'pointer' }, onClick: function () { return redirectToHomePage(); } }, { children: "Homepage" }))] })));
};
export default NotFoundPage;
