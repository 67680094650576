var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
var SystemCheck = function (props) {
    var _a = useState(true), Inicio = _a[0], setInicio = _a[1];
    useEffect(function () { }, [Inicio]);
    return (_jsx(_Fragment, { children: _jsx(AppBar, __assign({ position: 'relative' }, { children: _jsx(Toolbar, { children: _jsx(Typography, __assign({ variant: 'h6', color: 'inherit', noWrap: true }, { children: "Teste do OpenShif de Liveness" })) }) })) }));
};
export default SystemCheck;
