var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, MenuItem, Divider } from '@mui/material';
import { MODOS, MENSAGENS, validarCPF, ucFirstAllWords, getNomeFormatado, validarEmail } from '../../utils';
import { ROUTES, PAGINAS } from '../../routes';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../components/form_box';
import { BoxGrid } from '../../components/grid_box';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { getCombosUsuarios, getUsuario, cadastrarUsuario, alterarUsuario, excluirUsuario } from '../../api';
import { BotaoSalvar, BotaoExcluir, BotaoFechar, BotaoEditar } from '../../components/botoes';
import InputDados from '../../components/input_dados';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FuncionalidadesAcoes from '../../components/funcionalidades_acoes';
import GridComp from '../../components/grid';
var UsuariosEditar = function (props) {
    var navigate = useNavigate();
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(true), LoadingInicio = _b[0], setLoadingInicio = _b[1];
    var _c = useParams(), id = _c.id, modo = _c.modo;
    var _d = useContext(AuthContext) || { addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, addMensagem = _d.addMensagem, getAcoesFuncionalidade = _d.getAcoesFuncionalidade;
    var _e = React.useState(getAcoesFuncionalidade(PAGINAS.USUARIOS)), AcoesFuncionalidade = _e[0], setAcoesFuncionalidade = _e[1];
    var _f = useState({}), Usuario = _f[0], setUsuario = _f[1];
    var _g = useState(''), Nome = _g[0], setNome = _g[1];
    var _h = useState(''), CPF = _h[0], setCPF = _h[1];
    var _j = useState(null), Email = _j[0], setEmail = _j[1];
    var _k = useState(null), Matricula = _k[0], setMatricula = _k[1];
    var _l = useState(null), OrgaoSelecionado = _l[0], setOrgaoSelecionado = _l[1];
    var _m = useState(null), DepartamentoSelecionado = _m[0], setDepartamentoSelecionado = _m[1];
    var _o = useState(null), TipoServidorSelecionado = _o[0], setTipoServidorSelecionado = _o[1];
    var _p = useState([]), OrgaosDisponiveis = _p[0], setOrgaosDisponiveis = _p[1];
    var _q = useState([]), DepartamentosDisponiveis = _q[0], setDepartamentosDisponiveis = _q[1];
    var _r = useState([]), TiposServidoresDisponiveis = _r[0], setTiposServidoresDisponiveis = _r[1];
    var _s = useState(null), PerfilCampoAux = _s[0], setPerfilCampoAux = _s[1];
    var _t = useState(null), FuncionalidadeCampoAux = _t[0], setFuncionalidadeCampoAux = _t[1];
    var _u = useState([]), PerfisAcessoDisponiveis = _u[0], setPerfisAcessoDisponiveis = _u[1];
    var _v = useState([]), PerfisAcessoSelecionados = _v[0], setPerfisAcessoSelecionados = _v[1];
    var _w = useState([]), FuncionalidadesDisponiveis = _w[0], setFuncionalidadesDisponiveis = _w[1];
    var _x = useState([]), FuncionalidadesSelecionadas = _x[0], setFuncionalidadesSelecionadas = _x[1];
    var _y = useState('Ativo'), Situacao = _y[0], setSituacao = _y[1];
    var _z = useState(false), RegistroPodeSerExcluido = _z[0], setRegistroPodeSerExcluido = _z[1];
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var DadosCombos, UsuarioAux, nome, cpf, email, matricula, orgao_id, departamento_id, tipo_servidor_id, situacao, perfis, funcionalidades_acoes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCombosUsuarios()];
                case 1:
                    DadosCombos = _a.sent();
                    if (DadosCombos) {
                        setOrgaosDisponiveis(DadosCombos.orgaos);
                        setDepartamentosDisponiveis(DadosCombos.departamentos);
                        setTiposServidoresDisponiveis(DadosCombos.tipos_servidores);
                        setPerfisAcessoDisponiveis(DadosCombos.perfis);
                        setFuncionalidadesDisponiveis(DadosCombos.funcionalidades);
                    }
                    if (!id) return [3 /*break*/, 3];
                    return [4 /*yield*/, getUsuario(id)];
                case 2:
                    UsuarioAux = _a.sent();
                    setUsuario(UsuarioAux);
                    //console.log('Usuario', JSON.stringify(UsuarioAux));
                    if (UsuarioAux) {
                        nome = UsuarioAux.nome, cpf = UsuarioAux.cpf, email = UsuarioAux.email, matricula = UsuarioAux.matricula, orgao_id = UsuarioAux.orgao_id, departamento_id = UsuarioAux.departamento_id, tipo_servidor_id = UsuarioAux.tipo_servidor_id, situacao = UsuarioAux.situacao, perfis = UsuarioAux.perfis, funcionalidades_acoes = UsuarioAux.funcionalidades_acoes;
                        setNome(nome);
                        setCPF(cpf);
                        setEmail(email);
                        setMatricula(matricula);
                        setOrgaoSelecionado(orgao_id);
                        setDepartamentoSelecionado(departamento_id);
                        setTipoServidorSelecionado(tipo_servidor_id);
                        setSituacao(situacao);
                        setPerfisAcessoSelecionados(perfis !== null && perfis !== void 0 ? perfis : []);
                        setFuncionalidadesSelecionadas(funcionalidades_acoes !== null && funcionalidades_acoes !== void 0 ? funcionalidades_acoes : []);
                    }
                    _a.label = 3;
                case 3:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!Nome || Nome.trim() == '') {
            alert("Nome ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        else {
            var NomeAux = getNomeFormatado(Nome);
            if (!NomeAux || NomeAux.trim() == '') {
                alert("Nome ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
                return false;
            }
        }
        if (!CPF || CPF.trim() == '') {
            alert("CPF ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        else {
            var RegexCPF = new RegExp(/^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/);
            if (!RegexCPF.test(CPF) || !validarCPF(CPF)) {
                alert("CPF ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
                return false;
            }
        }
        if (Email) {
            if (!validarEmail(Email)) {
                alert("Email ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
                return false;
            }
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var FuncionalidadesAux, er, Dados, Resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    FuncionalidadesAux = FuncionalidadesSelecionadas.filter(function (item) { return item.funcionalidade_id != 0; });
                    er = /[^a-z0-9]/gi;
                    Dados = {
                        nome: ucFirstAllWords(getNomeFormatado(Nome, false)),
                        cpf: CPF.replace(/[^0-9]/g, ''),
                        email: Email ? Email.toLowerCase().trim() : null,
                        matricula: Matricula ? Matricula.replace(/\D/g, '') : '',
                        orgao_id: OrgaoSelecionado && OrgaoSelecionado != '-1' ? OrgaoSelecionado : null,
                        departamento_id: DepartamentoSelecionado && DepartamentoSelecionado != '-1' ? DepartamentoSelecionado : null,
                        tipo_servidor_id: TipoServidorSelecionado && TipoServidorSelecionado != '-1' ? TipoServidorSelecionado : null,
                        situacao: Situacao,
                        perfis: PerfisAcessoSelecionados,
                        funcionalidades_acoes: FuncionalidadesAux,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                        case MODOS.EXCLUIR: return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 7];
                case 1: return [4 /*yield*/, cadastrarUsuario(Dados)];
                case 2:
                    Resp = _b.sent();
                    return [3 /*break*/, 7];
                case 3: return [4 /*yield*/, alterarUsuario(Dados, id !== null && id !== void 0 ? id : '')];
                case 4:
                    Resp = _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, alterarUsuario(Dados, id !== null && id !== void 0 ? id : '')];
                case 6:
                    Resp = _b.sent();
                    return [3 /*break*/, 7];
                case 7:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        navigate(ROUTES.USUARIOS);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var excluirRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, excluirUsuario(id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MENSAGENS.REGISTRO_EXCLUIDO_SUCESSO);
                        navigate(ROUTES.USUARIOS);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(ROUTES.USUARIOS, "/").concat(id, "/").concat(MODOS.ALTERAR));
            return [2 /*return*/];
        });
    }); };
    var COLUNAS = [
        {
            field: 'nome',
            headerName: 'Perfil',
            width: 400,
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            //description: 'Situação',
            width: 150,
            //valueGetter: (value, row) => `${row.status == 'Aitvo' || ''} ${row.descricao || ''}`,
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 170,
            renderCell: function (params) { return (_jsx(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: _jsx(Box, __assign({ bgcolor: 'error.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                            var PerfisAcessoSelecionadosAux = PerfisAcessoSelecionados.filter(function (item) { return item.id != params.row.id; });
                            setPerfisAcessoSelecionados(PerfisAcessoSelecionadosAux);
                        }, disabled: DESABILITAR_CAMPOS }, { children: _jsx(DeleteOutlineIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))); },
        },
    ];
    var adicionarPerfil = function () {
        if (!PerfilCampoAux || PerfilCampoAux == '-1') {
            alert('Selecione um perfil');
            return;
        }
        var PerfilSelecionado = PerfisAcessoDisponiveis.find(function (item) { return item.id == PerfilCampoAux; });
        setPerfilCampoAux(null);
        if (PerfilSelecionado) {
            if (PerfisAcessoSelecionados.find(function (item) { return item.id == PerfilSelecionado.id; })) {
                alert('Perfil já adicionado');
                return;
            }
            setPerfisAcessoSelecionados(__spreadArray(__spreadArray([], PerfisAcessoSelecionados, true), [PerfilSelecionado], false));
        }
    };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.USUARIOS, link_pagina_acima: ROUTES.USUARIOS }, { children: _jsxs(BoxPrincipal, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsx(InputDados, { id: 'id', variant: 'outlined', disabled: true, label: 'C\u00F3digo', value: id, size: 'small' }), _jsx(InputDados, { id: 'nome', label: 'Nome', value: Nome, onChange: function (event) { return setNome(event.target.value); }, variant: 'outlined', disabled: DESABILITAR_CAMPOS, required: true }), _jsx(InputDados, { id: 'cpf', label: 'CPF', value: CPF, mask: '999.999.999-99', onChange: function (event) { return setCPF(event.target.value); }, variant: 'outlined', disabled: DESABILITAR_CAMPOS, required: true }), _jsx(InputDados, { id: 'email', label: 'Email', value: Email, onChange: function (event) { return setEmail(event.target.value); }, variant: 'outlined', disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { id: 'matricula', label: 'Matricula', value: Matricula, onChange: function (event) { return setMatricula(event.target.value); }, variant: 'outlined', fullWidth: true, mask: '999999999999', disabled: DESABILITAR_CAMPOS }), _jsxs(InputDados, __assign({ select: true, id: 'tipo_servidor' // obrigatorio
                            , label: 'Tipo de Servidor' // obrigatorio
                            , placeholder: 'Tipo de Servidor', value: TipoServidorSelecionado, onChange: function (event) {
                                setTipoServidorSelecionado(event.target.value);
                            }, fullWidth: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: 'Selecione ...' }), '-1'), TiposServidoresDisponiveis &&
                                    TiposServidoresDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'departamento' // obrigatorio
                            , label: 'Departamento' // obrigatorio
                            , placeholder: 'Departamento', value: DepartamentoSelecionado, onChange: function (event) {
                                setDepartamentoSelecionado(event.target.value);
                            }, fullWidth: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: 'Selecione ...' }), '-1'), DepartamentosDisponiveis &&
                                    DepartamentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'orago' // obrigatorio
                            , label: '\u00D3rg\u00E3os/Empresas' // obrigatorio
                            , placeholder: '\u00D3rg\u00E3os/Empresas', value: OrgaoSelecionado, onChange: function (event) {
                                setOrgaoSelecionado(event.target.value);
                            }, fullWidth: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: 'Selecione ...' }), '-1'), OrgaosDisponiveis &&
                                    OrgaosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'situacao' // obrigatorio
                            , label: 'Situa\u00E7\u00E3o' // obrigatorio
                            , placeholder: 'Situa\u00E7\u00E3o', value: Situacao, onChange: function (event) {
                                setSituacao(event.target.value);
                            }, size: 'small', fullWidth: true, disabled: MODOS.VISUALIZAR == modo }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })), _jsx(Divider, __assign({ style: { marginTop: 20, marginBottom: 20, color: 'grey' } }, { children: "PERFIS DE ACESSO" })), _jsxs(Box, { children: [_jsxs(Box, __assign({ style: { display: 'flex', justifyContent: 'space-around' } }, { children: [_jsxs(InputDados, __assign({ select: true, id: 'perfil' // obrigatorio
                                            , label: 'Perfil' // obrigatorio
                                            , placeholder: 'Perfil', value: PerfilCampoAux, onChange: function (event) {
                                                setPerfilCampoAux(event.target.value);
                                            }, fullWidth: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: 'Selecione ...' }), '-1'), PerfisAcessoDisponiveis &&
                                                    PerfisAcessoDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsx(Box, { children: _jsx(Button, __assign({ variant: 'contained', style: { backgroundColor: '#109fef' }, onClick: adicionarPerfil, sx: { marginLeft: 10, marginRight: '30%' }, disabled: DESABILITAR_CAMPOS }, { children: "Adicionar" })) })] })), _jsx(BoxGrid, { children: _jsx(GridComp, { rows: PerfisAcessoSelecionados, columns: COLUNAS, loading: loading, paginationMode: 'client', onPaginationModelChange: function () { } }) })] }), _jsx(Divider, __assign({ style: { marginTop: 20, marginBottom: 20, color: 'grey' } }, { children: "CONFIGURA\u00C7\u00D5ES ADICIONAIS DE PERMISS\u00D5ES" })), _jsx(FuncionalidadesAcoes, { FuncionalidadesDisponiveis: FuncionalidadesDisponiveis, FuncionalidadesSelecionadas: FuncionalidadesSelecionadas, setFuncionalidadesSelecionadas: setFuncionalidadesSelecionadas, DESABILITAR_CAMPOS: DESABILITAR_CAMPOS }), _jsxs(BoxBotoes, __assign({ sx: { marginTop: 15 } }, { children: [!DESABILITAR_CAMPOS && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.EXCLUIR == modo && _jsx(BotaoExcluir, { onClick: excluirRegistro, disabled: !RegistroPodeSerExcluido }), MODOS.VISUALIZAR == modo && _jsx(BotaoEditar, { onClick: editarRegistro, disabled: !AcoesFuncionalidade[MODOS.ALTERAR] }), _jsx(BotaoFechar, { modo: modo })] }))] })), loading && _jsx(Loading, {})] }) })));
};
export default UsuariosEditar;
