var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SnackbarProvider } from 'notistack';
import React from 'react';
import Root from './Root';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles, createTheme } from '@mui/material';
import { CacheProvider } from '@emotion/react';
var App = function (_a) {
    var cache = _a.cache;
    return (_jsx(CacheProvider, __assign({ value: cache }, { children: _jsxs(ThemeProvider, __assign({ theme: createTheme() }, { children: [_jsx(GlobalStyles, { styles: function () { return ({}); } }), _jsxs(SnackbarProvider, __assign({ maxSnack: 3, autoHideDuration: 3000, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    } }, { children: [_jsx(CssBaseline, {}), _jsx(Root, {})] }))] })) })));
};
export default App;
