var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { WebgateProvider as _WebgateProvider } from '@xvia/webgate-connect';
import { BrowserRouter } from 'react-router-dom';
import createCache from '@emotion/cache';
var WebgateProvider = _WebgateProvider;
var baseName = (window === null || window === void 0 ? void 0 : window.__APP_BASE__) || '/';
var cache = createCache({ key: 'css' });
cache.compat = true;
var PORTAL_URL = process.env.PORTAL_URL;
var root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(_jsx(BrowserRouter, __assign({ basename: baseName }, { children: _jsx(WebgateProvider, __assign({ portalUrl: PORTAL_URL }, { children: _jsx(App, { cache: cache }) })) })));
