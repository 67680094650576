var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MODOS, MENSAGENS } from '../../utils';
export var BotaoAuxiliar = function (props) {
    var children = props.children, backgroundColor = props.backgroundColor, sx = props.sx, rest = __rest(props, ["children", "backgroundColor", "sx"]);
    return (_jsx(Button, __assign({ variant: 'contained', sx: __assign({ backgroundColor: backgroundColor, ':hover': {
                bgcolor: backgroundColor,
            } }, sx) }, rest, { children: children })));
};
export var BotaoAdicionar = function (props) {
    var pagina = props.pagina, label = props.label;
    var navigate = useNavigate();
    return (_jsx(Box, { children: _jsx(BotaoAuxiliar, __assign({ onClick: function () {
                navigate("".concat(pagina, "/").concat(MODOS.CADASTRAR));
            }, sx: { marginLeft: 10, marginRight: '30%' }, backgroundColor: 'terceary.main' }, props, { children: label !== null && label !== void 0 ? label : 'Adicionar' })) }));
};
export var BotaoSalvar = function (props) {
    var _a;
    return (_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'terceary.main' }, props, { children: (_a = props.label) !== null && _a !== void 0 ? _a : 'Salvar' })));
};
export var BotaoAvancar = function (props) {
    return (_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'primary.main' }, props, { children: "Avan\u00E7ar" })));
};
export var BotaoEditar = function (props) {
    return (_jsx(Box, { children: _jsx(BotaoAuxiliar, __assign({ backgroundColor: 'terceary.main' }, props, { children: "Editar" })) }));
};
export var BotaoExcluir = function (props) {
    return (_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'error.main' }, props, { children: "Excluir" })));
};
export var BotaoFechar = function (props) {
    var navigate = useNavigate();
    var modo = props.modo, pagina = props.pagina, label = props.label;
    return (_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'secondary.main' }, props, { onClick: function () {
            modo == MODOS.VISUALIZAR ? navigate(pagina !== null && pagina !== void 0 ? pagina : -1) : confirm(MENSAGENS.SAIR_TELA) && navigate(pagina !== null && pagina !== void 0 ? pagina : -1);
        } }, props, { children: label !== null && label !== void 0 ? label : 'Fechar' })));
};
